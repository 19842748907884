/** This file contains all the common methods that cann be used by various modules */
import React from 'react';

/**
 * THis function creates an image component for the image requested
 * @param {String} imageName Name of the image to be fetched from the image folder
 * @returns the image component that was requested by the calling function
 */
export function createImagecomponent(imageName, cssClass) {
    let imageElement = React.createElement(
        'img',
        {
            src: require('../assets/' + imageName + '').default,
            className: cssClass,
        },
        null,
    );
    return imageElement;
}

// group the list by the preceding substring
export function mapListToDic(l) {
    let l2 = l.map((item) => {
        return item.split(' ');
    });

    let ans = {};
    let prefix = '';
    for (let i = 0; i < l2.length; ++i) {
        let item1 = l2[i];

        if (i === l2.length - 1) {
            if (!(prefix && l[i].startsWith(prefix))) {
                ans[l[i]] = [];
            }
            continue;
        }

        let item2 = l2[i + 1];
        let j = 0;
        prefix = '';
        let nameList = [];

        while (item1[j] === item2[j]) {
            prefix += item1[j] + ' ';
            ++j;
        }

        if (prefix === '') {
            ans[l[i]] = [];
            continue;
        }

        nameList = l
            .filter((item) => {
                return item.startsWith(prefix);
            })
            .map((item) => {
                let end = item.indexOf(prefix) + prefix.length;
                return item.substring(end);
            });
        ans[prefix] = nameList;
        while (i < l2.length && l[i].includes(prefix)) {
            i++;
        }
        if (i !== l2.length) {
            i -= 1;
        }
    }

    return ans;
}
export function truncateDecimal(number, decimalPlaces) {
    const multiplier = Math.pow(10, decimalPlaces);
    return Math.trunc(number * multiplier) / multiplier;
}

export const openInNewTab = (url) => {
    window.open(url, '_blank')?.focus();
};

export const isNumber = (str) => {
    const numberOrFloatRegex = /^([0-9]+([.][0-9]*)?|[.][0-9]+)$/;
    return numberOrFloatRegex.test(str) && str.indexOf('.') === str.lastIndexOf('.');
};

export const isFloat = (value, decimalPlaces = 2) => {
    const regex = new RegExp(`^\\d+(?:\\.\\d{0,${decimalPlaces}})?$`, 'i');
    return value === '' || regex.test(value);
};

function check(num) {
    if (num === undefined || num === null || isNaN(num) | (num === '')) return 'N/A';
    return num;
}

export function round(num) {
    return check(
        parseFloat(num)
            .toFixed(2)
            .replace(/\.?0+$/, ''),
    );
}
