import React, { useState, useEffect } from 'react';
import MusicAuditor from './MusicAuditor';
import { getTargetReductionsAndWqMethod } from '../../utils/api/lambda';
import { selectUserInfo } from '../../utils/redux/userInfoSlice';
import { selectProjectInfo } from '../../utils/redux/projectInfoSlice';
import { selectMapInfo } from '../../utils/redux/mapInfoSlice';
import { useSelector } from 'react-redux';
import styles from './Auditor.module.css';
import { Button } from 'react-bootstrap';
import MxprojConverterContainer from './MxprojConverter/mxprojConverter';

import AuditorLogo from '../../assets/Auditor Logo.png';
import ConverterLogo from '../../assets/File_Converter_1_resized.png';

const AuditorContainer = () => {
    const userInfo = useSelector(selectUserInfo);
    const projectInfo = useSelector(selectProjectInfo);
    const mapInfo = useSelector(selectMapInfo);

    const [targetReductions, setTargetReductions] = useState(null);
    const [wqMethod, setWQMethod] = useState(null);
    const [tool, setTool] = useState(null);

    const restrictedCouncils = [
        'Ballina Shire Council',
        'City of Blacktown',
        'Camden Council',
        'Central Coast Council',
        'City of Sydney',
        'Clarence Valley Council',
        'Coffs Harbour City Council',
        'The Council of the Shire of Hornsby',
        'Ku-Ring Gai Council',
        'Lake Macquarie City Council',
        'Liverpool City Council',
        'Penrith City Council',
        'Port Stephens Council',
        'Newcastle City Council',
        'Shoalhaven City Council',
        'City of Parramatta Council',
    ];

    useEffect(() => {
        document.title = 'Auditor';
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if (!mapInfo) return;

            getTargetReductionsAndWqMethod(mapInfo.state, mapInfo.rainfallStation)
                .then((res) => {
                    setTargetReductions(res[0]);
                    setWQMethod(res[1]);
                })
                .catch((error) => {
                    console.error(error);
                });
        };
        fetchData();
    }, [mapInfo]);

    const handleAuditClick = () => {
        if (restrictedCouncils.includes(mapInfo.council)) {
            alert(
                `We're aware that for a limited time, audits for Councils with MUSIC-Link will get flagged as being non-compliant (we expect this to be fixed by 20th September). Apologies for any inconvenience. Please contact mircea@cleanstormwater.com.au for help.`,
            );
            setTool(0);
        } else {
            setTool(0);
        }
    };

    return (
        <>
            <section className={styles.body}>
                <div className="mt-2">
                    Project Address{': '}
                    <strong>{mapInfo.address}</strong>
                </div>
                <div>
                    Council{': '}
                    <strong>{mapInfo.council}</strong>
                </div>
                <div>
                    Coordinates: LAT {': '}
                    <strong>{mapInfo.coordinate.lat}</strong>, LNG{': '}
                    <strong>{mapInfo.coordinate.lng}</strong>
                </div>
            </section>
            {targetReductions && (
                <div className={styles['auditor-form']}>
                    <div className={styles['tool-selector-buttons']}>
                        <div className="w-50 d-flex gap-5">
                            <Button
                                className={`${styles['button-light-blue']} ${styles['tool-button']}`}
                                onClick={handleAuditClick}
                                disabled={mapInfo.rainfallStation === ''}
                            >
                                <img
                                    src={AuditorLogo}
                                    alt={'Auditor Logo'}
                                    className={styles['buttonIcon']}
                                ></img>
                                Audit MUSIC Model
                            </Button>
                            <Button
                                className={`${styles['button-green']} ${styles['tool-button']}`}
                                onClick={() => setTool(1)}
                            >
                                <img
                                    src={ConverterLogo}
                                    alt={'Converter Logo'}
                                    className={styles['buttonIcon']}
                                ></img>
                                Convert MXProj to MSF
                            </Button>
                        </div>
                    </div>
                    {tool !== null && (
                        <div className="d-flex justify-content-center">
                            <div className={`${styles['divider']} my-4`} />
                        </div>
                    )}
                    {tool === 0 && (
                        <div className="mt-3">
                            <MusicAuditor
                                userInfo={userInfo}
                                mapInfo={mapInfo}
                                projectInfo={projectInfo}
                                targetReductions={targetReductions}
                                wqMethod={wqMethod}
                            />
                        </div>
                    )}
                    {tool === 1 && (
                        <div className="mt-3">
                            <MxprojConverterContainer />
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default AuditorContainer;
