import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet, pdf } from '@react-pdf/renderer';
// components
import BasicInformation from '../BasicInformation';
import SwinburneReportTemplate from './SwinburneReportTemplate';
import SpecImagePages from '../SpecImagePages';
import { data as datum } from './payload';
import DesignCalculationDetails from './DesignCalculationDetails';
import ReportHeader from '../ReportHeader';
import ReportFooter from '../ReportFooter';
import OsdRangeOfDurationsCalculatedPage from './../sharedPages/OsdRangeOfDurationsCalculatedPage';

const getSwinburneReport = (data, manufactureImageLinks, manufactureDocuments) => {
    const styles = StyleSheet.create({
        page: {
            paddingTop: 30,
            paddingBottom: 10,
            paddingHorizontal: 35,
        },
        imageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
        },
        image: {
            paddingTop: 30,
            height: 350,
            width: 400,
        },
        mainTitle: {
            fontSize: 18,
            fontweight: 700,
            textAlign: 'center',
            marginTop: 5,
            marginBottom: 20,
        },
    });

    return (
        <Document>
            <BasicInformation
                reportHeaderUrl={data.reportHeaderIconUrl}
                address={{
                    suburb: data.suburb,
                    state: data.state,
                    postcode: data.postcode,
                    council: data.council,
                    address: data.address,
                    coordinate: data.coordinate,
                }}
                basicInfo={{
                    developmentType: data.developmentType,
                    permit: data.planningPermitNo,
                    projectName: 'N/A',
                    assessor: data.assessorName,
                    email: data.assessorEmail,
                }}
            />

            {/* OSD Summary*/}
            <SwinburneReportTemplate data={data} />

            <DesignCalculationDetails data={data} />

            {data.osd.tDurChart && data.osd.tDurChart !== '' && (
                <>
                    {/* Storm Duration Chart */}
                    <Page style={styles.page} size="A4">
                        <View>
                            <ReportHeader reportHeaderUrl={data.reportHeaderIconUrl} />
                        </View>
                        <View>
                            <Text style={styles.mainTitle}>
                                Range of Durations used to Determine Max volume Required
                            </Text>
                        </View>
                        <View style={styles.imageContainer}>
                            <Image src={data.osd.tDurChart} style={styles.image} />
                        </View>
                        <ReportFooter />
                    </Page>

                    {/* Storm Duration Table */}
                    <OsdRangeOfDurationsCalculatedPage
                        vreq_n_data={data.osdResult.vreq_n_data}
                        reportHeaderIconUrl={data.reportHeaderIconUrl}
                        type="Swinburne"
                        psd={(
                            (data.osd.psd ? +data.osd.psd : +data.osdResult.PSD) - data.osdResult.Qu
                        ).toFixed(4)}
                        Qu={data.osdResult.Qu.toFixed(4)}
                    />
                </>
            )}

            {/* Images */}
            {manufactureImageLinks.length > 0 && (
                <SpecImagePages
                    osdCompany={data.OSDCompany}
                    manufactureDocuments={manufactureDocuments}
                    manufactureImageLinks={manufactureImageLinks}
                    reportHeaderUrl={data.reportHeaderIconUrl}
                />
            )}
        </Document>
    );
};

// main function
export const generateSwinburneReport = async (
    data,
    manufactureImageLinks,
    manufactureDocuments,
) => {
    try {
        const reportBlob = await pdf(
            getSwinburneReport(data, manufactureImageLinks, manufactureDocuments),
        ).toBlob();
        return reportBlob;
    } catch (err) {
        console.error(err);
    }
};
