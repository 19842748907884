import { Button, Spinner } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import './RwtCalculatorContainer.css';
import {
    TooltipContainer,
    TooltipContent,
    TooltipButton,
} from '../../styled-components/TooltipComponents';

const RwtCalculatorContainer = ({
    children,
    backgroundColor = '',
    title,
    footer,
    selectedMethod,
    onMethodChange,
    methods = [],
    result = {},
    isReady,
    inProgress,
    onSubmit,
}) => {
    // Construct options
    const methodOptions = methods.map((method, i) => (
        <option key={i} value={method.id}>
            {method.label}
        </option>
    ));

    const resultValue = result.value ?? 'N/A';

    return (
        <section className="rwt-calculator-container osd-blue-border" style={{ backgroundColor }}>
            <div className="rwt-calculator-header">
                <h2 className="rwt-calculator-title">
                    {title}
                    {title === 'Indoor Demand Calculator' && (
                        <TooltipContainer className="ml-2">
                            <TooltipButton>❓</TooltipButton>
                            <TooltipContent>
                                <span>Calculate how many kiloliters/occupant/day</span>
                            </TooltipContent>
                        </TooltipContainer>
                    )}
                    {title === 'Indoor Occupancy Calculator' && (
                        <TooltipContainer className="ml-2">
                            <TooltipButton>❓</TooltipButton>
                            <TooltipContent>
                                <span>
                                    This section helps to estimate number of occupants. For
                                    extensive development use types (factories, warehouses, schools,
                                    theatres etc.) please use the South Australian MUSIC Modelling
                                    Guidelines 2021 method in dropdown below.
                                </span>
                            </TooltipContent>
                        </TooltipContainer>
                    )}
                </h2>
            </div>
            <div className="rwt-calculator-body">
                {isReady ? (
                    <>
                        <Form.Control
                            as="select"
                            defaultValue=""
                            className="custom-bootstrap-select-input"
                            onChange={(e) =>
                                onMethodChange(
                                    methods.find((method) => method.id === e.target.value),
                                )
                            }
                        >
                            <option value="">Select Method ...</option>
                            {methodOptions}
                        </Form.Control>
                        {selectedMethod ? children : null}
                    </>
                ) : (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            opacity: 0.5,
                            gap: '0.5rem',
                        }}
                    >
                        <Spinner animation="border" size="sm" />
                        <span>Loading ...</span>
                    </div>
                )}
            </div>
            <div className="rwt-calculator-footer">
                {footer && <div>{footer}</div>}
                <div className="result">
                    <Button
                        variant="info"
                        onClick={onSubmit}
                        disabled={selectedMethod === undefined || inProgress}
                    >
                        Calculate
                    </Button>
                    <p style={{ fontSize: '1.5rem' }}>
                        {result.label ?? 'Result'}: {inProgress ? 'Calculating...' : resultValue}
                    </p>
                </div>
            </div>
        </section>
    );
};

export default RwtCalculatorContainer;
