import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// utils
import { selectMapInfo } from '../../../utils/redux/mapInfoSlice';
import { selectUserInfo } from '../../../utils/redux/userInfoSlice';
import { getTargetReductionsAndWqMethod } from '../../../utils/api/lambda';
import { setArea } from '../../../utils/redux/WSUD/areaSlice';
import { setSiteDetails } from '../../../utils/redux/WSUD/siteDetailsSlice';
// components
import WSUDForm from './WSUDForm';
import WSUDButtons from '../WSUDButtons';
import CouncilRule from '../../council-rule/CouncilRule';
import ReportNoteSection from '../../report-note-section/ReportNoteSection';

/**
 * this component loads all the necessary data required for the WSUD form from the address information
 */
const WSUDContainer = ({ loadedModel }) => {
    const dispatch = useDispatch();
    const mapInfo = useSelector(selectMapInfo);
    const userInfo = useSelector(selectUserInfo);

    const [targetReductions, setTargetReductions] = useState(null);
    const [wsudReady, setWsudReady] = useState(false);

    useEffect(() => {
        document.title = 'SU Calc';
    }, []);

    useEffect(() => {
        if (loadedModel) {
            dispatch(setSiteDetails(loadedModel.eol_data));
            dispatch(
                setArea({
                    pervious: loadedModel.pervious_area,
                    impervious: loadedModel.impervious_area,
                }),
            );
        }
    }, [loadedModel]);

    useEffect(() => {
        const fetchData = async () => {
            if (!mapInfo) return;

            getTargetReductionsAndWqMethod(mapInfo.state, mapInfo.rainfallStation)
                .then((res) => setTargetReductions(res[0]))
                .catch((error) => {
                    console.error(error);
                });
        };
        fetchData();
    }, [mapInfo]);

    return (
        <>
            {targetReductions && (
                <>
                    <ReportNoteSection />
                    <CouncilRule mlbName={mapInfo.rainfallStation} />
                    <section>
                        <span>
                            You now need to list every impervious area (hard surfaces e.g. roof,
                            road) and pervious area (e.g. landscape) on your site and detail your
                            planned treatment measures. All surface areas must be listed with their
                            area, if there is no treatment choose NONE in the treatment field box.
                        </span>
                        <div className="mt-1">
                            - You can add or delete rows by selecting the Add Impervious Area or Add
                            Pervious Area and 🗑 buttons.
                        </div>
                        <div>
                            - Water Quality results are shown live (when we change the size of a
                            treatment type, the reductions will change accordingly).
                        </div>
                        <div className="mt-3">
                            Project Address{': '}
                            <strong>{mapInfo.address}</strong>
                        </div>
                        <div className="mt-1">
                            Council{': '}
                            <strong>{mapInfo.council}</strong>
                        </div>
                        <div className="mt-1 mb-3 ">
                            Coordinates: LAT {': '}
                            <strong>{mapInfo.coordinate.lat}</strong>, LNG{': '}
                            <strong>{mapInfo.coordinate.lng}</strong>
                        </div>
                    </section>
                    <WSUDForm
                        rainfallStation={mapInfo.rainfallStation}
                        cityCouncil={mapInfo.council}
                        state={mapInfo.state}
                        targetReductions={targetReductions}
                        setWsudReady={setWsudReady}
                    />
                    <section className="text-center mt-1">
                        <WSUDButtons
                            wsudReady={wsudReady}
                            targetReductions={targetReductions}
                            userInfo={userInfo}
                            mapInfo={mapInfo}
                        />
                    </section>
                </>
            )}
        </>
    );
};

export default WSUDContainer;
