import React from 'react';
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer';

import BelowGroundTank from '../../../Components/assets/psd_calculations/BelowGroundTank.png';
import BelowGroundPipe from '../../../Components/assets/psd_calculations/BelowGroundPipe.png';
import AboveGroundStorage from '../../../Components/assets/psd_calculations/AboveGroundStorage.png';

const PSDCalculations = ({ storageType }) => {
    const styles = StyleSheet.create({
        subtitle: {
            fontSize: 15,
            fontWeight: 400,
            marginTop: 10,
            marginBottom: 10,
        },
        image: {
            objectFit: 'contain',
            width: '90%',
            height: 'auto',
            marginVertical: 10,
        },
        text: {
            fontSize: 10,
            marginVertical: 2,
        },
    });

    switch (storageType.toLowerCase()) {
        case 'below ground tank':
            return (
                <View wrap>
                    <View style={{ marginLeft: '10%' }}>
                        <Image src={BelowGroundTank} style={styles.image} />
                    </View>
                    <Text style={styles.text}>Where:</Text>
                    <Text style={styles.text}>PSD = Permissible site discharge</Text>
                    <Text style={styles.text}>Qp = Pre development flow</Text>
                    <Text style={styles.text}>Qa = Post Development flow</Text>
                    <Text style={styles.text}>Tcs = Time from catchment to site</Text>
                    <Text style={styles.text}>Tc = Time of concentration for catchment</Text>
                    <Text style={styles.text}>
                        *See parameter values in OSD Design Summary Report (Page 2)
                    </Text>
                </View>
            );
        case 'below ground pipe':
            return (
                <View wrap>
                    <View style={{ marginLeft: '10%' }}>
                        <Image src={BelowGroundPipe} style={styles.image} />
                    </View>
                    <Text style={styles.text}>Where:</Text>
                    <Text style={styles.text}>PSD = Permissible site discharge</Text>
                    <Text style={styles.text}>Qp = Pre development flow</Text>
                    <Text style={styles.text}>Qa = Post Development flow</Text>
                    <Text style={styles.text}>Tcs = Time from catchment to site</Text>
                    <Text style={styles.text}>Tc = Time of concentration for catchment</Text>
                    <Text style={styles.text}>
                        *See parameter values in OSD Design Summary Report (Page 2)
                    </Text>
                </View>
            );
        case 'above ground storage':
            return (
                <View wrap>
                    <View style={{ marginLeft: '10%' }}>
                        <Image src={AboveGroundStorage} style={styles.image} />
                    </View>
                    <Text style={styles.text}>Where:</Text>
                    <Text style={styles.text}>PSD = Permissible site discharge</Text>
                    <Text style={styles.text}>Qp = Pre development flow</Text>
                    <Text style={styles.text}>Qa = Post Development flow</Text>
                    <Text style={styles.text}>Tcs = Time from catchment to site</Text>
                    <Text style={styles.text}>Tc = Time of concentration for catchment</Text>
                    <Text style={styles.text}>
                        *See parameter values in OSD Design Summary Report (Page 2)
                    </Text>
                </View>
            );
        default:
            return (
                <View>
                    <Text style={styles.text}>No storage type selected.</Text>
                </View>
            );
    }
};

export default PSDCalculations;
