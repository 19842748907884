import React from 'react';
// utils
import { isFloat } from '../../../utils/common';
// components
import FormRow from './FormRow';

const OSDMethod = ({ method, setMethod }) => {
    const handleInputChange = (e, _, numberCheck = false) => {
        let { name, value } = e.target;

        if (numberCheck && !isFloat(e.target.value)) {
            return;
        }
        const bufArray = { ...method };
        bufArray[name] = value;
        setMethod(bufArray);
    };

    return (
        <div>
            <FormRow
                formRow={[method]}
                columns={[
                    {
                        id: 'qaMultiplicationFactor',
                        label: '',
                        inputType: 'dropdown',
                        options: [
                            { label: 'Swinburne OSD4w', value: 2 },
                            { label: 'Standard Swinburne', value: 1 },
                        ],
                        className: 'span-3',
                    },
                ]}
                handleInputChange={handleInputChange}
            />
        </div>
    );
};

export default OSDMethod;
