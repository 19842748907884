import { Form } from 'react-bootstrap';
import RwtCalculatorContainer from '../RwtCalculatorContainer/RwtCalculatorContainer';
import RendreDoc from '../../RenderDoc/RenderDoc';
import FormLabel from '../../FormLabel/FormLabel';
import { useDispatch } from 'react-redux';

import { isValidRealNumber } from '../../../validations/common';
import { setIrrigationDemand } from '../../../utils/redux/sizeRainwaterTankToolSlice';

import rwtApi from '../rwtApi';

export default function IrrigationDemandCalculator({
    isReady,
    methods,
    onMethodChange,
    annualEvapotranspiration,
    calculatorState,
    setCalculatorState,
}) {
    const dispatch = useDispatch();

    const calculateIrrigationrDemand = async () => {
        setCalculatorState((prev) => {
            return { ...prev, errors: {} };
        });

        // Parse fields
        const parsedData = {};
        const errors = {};
        setCalculatorState({ ...calculatorState, errors });
        for (const fieldKey in calculatorState.fields) {
            const field = calculatorState.fields[fieldKey];

            if (field.disabled) continue;

            // Add value to body if it's valid
            if (isValidRealNumber(field.value, 2)) parsedData[fieldKey] = parseFloat(field.value);
            // Set error
            else {
                errors[fieldKey] =
                    field.value.length === 0 ? 'Input field cannot be empty' : 'Invalid value';
                setCalculatorState({ ...calculatorState, errors });
            }
        }

        if (Object.keys(errors).length > 0) {
            setCalculatorState((prev) => {
                return { ...prev, errors, status: 'initial' };
            });
            return;
        } else {
            setCalculatorState((prev) => {
                return { ...prev, errors, status: 'inProgress' };
            });
        }

        // Prepare payload
        const body = { method: calculatorState.selectedMethod.id, params: {} };
        body.params = parsedData;

        // Send request to backend to perform calculations
        rwtApi
            .calculateIrrigationDemand(body)
            .then((result) => {
                // Set result
                setCalculatorState((prev) => {
                    return { ...prev, errors: {}, result, status: 'initial' };
                });
                dispatch(setIrrigationDemand(result));
            })
            .catch((error) => {
                // TODO: what should happen in an error
                alert(error.message);
                setCalculatorState((prev) => {
                    return { ...prev, status: 'initial' };
                });
            });
    };

    const onInputFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedState = { ...calculatorState };
        // Modify state
        if (value.length === 0 || (value.length > 0 && isValidRealNumber(value, 2)))
            updatedState.fields[name].value = value;
        updatedState.result = null;
        // Update state
        setCalculatorState(updatedState);
    };

    //-------------------------------------------
    // Set Method Doc Section
    //-------------------------------------------
    let methodDoc = null;
    if (calculatorState.selectedMethod?.info?.blocks)
        methodDoc = (
            <div className="doc-container">
                <RendreDoc blocks={calculatorState.selectedMethod?.info?.blocks} />
            </div>
        );

    //-------------------------------------------
    // Set Form
    //-------------------------------------------
    const form = (fields) => {
        const calculatorType = 'Irrigation';

        return (
            <form className="rwt-calculator-form">
                <div className="input-control-container">
                    <div
                        style={{
                            height: '100%',
                            padding: '5px',
                            fontSize: '14px',
                            border: '1px solid #33333333',
                            borderRadius: '5px',
                        }}
                    >
                        <p style={{ margin: 0, marginBottom: '5px' }}>
                            Annual evapotranspiration as per BoM Geogrids at this Lat/Long
                        </p>
                        <p style={{ margin: 0, fontWeight: 'bold' }}>
                            Annual Evapotranspiration: {annualEvapotranspiration} mm/year
                        </p>
                    </div>
                </div>
                {Object.keys(fields).map((fieldId) => {
                    const field = fields[fieldId];
                    return (
                        <div key={field.id} className="input-control-container">
                            <FormLabel htmlFor={`${calculatorType}-${field.id}`} info={field.info}>
                                {field.label}
                            </FormLabel>
                            <div>
                                <Form.Control
                                    id={`${calculatorType}-${field.id}`}
                                    name={`${field.id}`}
                                    disabled={field?.disabled}
                                    value={field.value}
                                    onChange={(e) => onInputFieldChange(e)}
                                />
                                <span className="input-control-error">
                                    {calculatorState.errors[field.id]}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </form>
        );
    };

    //-------------------------------------------
    // Component
    //-------------------------------------------
    return (
        <RwtCalculatorContainer
            isReady={isReady}
            backgroundColor="#8adfc728"
            title="Irrigation Demand Calculator"
            methods={methods}
            selectedMethod={calculatorState.selectedMethod?.id}
            onMethodChange={(method) => onMethodChange(method, calculatorState, setCalculatorState)}
            result={{ label: 'Total Demand, kL/year', value: calculatorState.result }}
            inProgress={calculatorState.status === 'inProgress'}
            onSubmit={calculateIrrigationrDemand}
        >
            {methodDoc}
            {form(calculatorState.fields)}
        </RwtCalculatorContainer>
    );
}
