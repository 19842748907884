import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

export const getThumbNailURL = (name) => `./src/assets/treatment-thumbnail/${name}.png`;

export const stringOrNumber = (value, decimalPlace = 2) => {
    if (isNaN(+value)) return value;
    else return (+value).toFixed(decimalPlace);
};

export const stringOrNumberWithSignificantVals = (value, significantFigures = 2) => {
    if (isNaN(+value)) return value;

    const numericValue = +value;
    const absoluteValue = Math.abs(numericValue);

    if (absoluteValue === 0) {
        return '0';
    }

    const decimalPartLength = value.toString().split('.')[1]?.length || 0;

    const multiplier = Math.pow(10, decimalPartLength + significantFigures);

    const roundedValue = Math.round(numericValue * multiplier) / multiplier;

    return roundedValue.toString();
};

export function usePrevious(value) {
    const ref = useRef(value);
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

export const openInNewTab = (url) => {
    window.open(url, '_blank')?.focus();
};

export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState('');
    const timerRef = useRef();

    useEffect(() => {
        timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [value, delay]);

    return debouncedValue;
};

export const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        const obj1 = arr1[i];
        const obj2 = arr2[i];

        for (const key in obj1) {
            if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
                if (obj1[key] !== obj2[key]) {
                    return false;
                }
            }
        }
    }

    return true;
};

export function shuffleArray(array) {
    const shuffledArray = [...array];

    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }

    return shuffledArray;
}

export async function downloadDrainsFiles(lat, lng, address) {
    try {
        const response = await fetch(
            'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/drain-files-download',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    lon_coord: lng,
                    lat_coord: lat,
                }),
            },
        );

        const responseData = await response.json();

        if (response.ok) {
            downloadFile(responseData.BomCSV, `IFD 2016 Rainfall ${address} .csv`, 'text/csv');
            downloadFile(responseData.TempPatZip, 'ARR TemporaryPatterns.zip', 'application/zip');
            downloadFile(responseData.MedianTxt, 'ARR MedianPreburst.txt', 'text/plain');
        } else {
            console.error(responseData);
        }
    } catch (error) {
        console.error('Error:', error);
    }
}

function downloadFile(base64Data, fileName, mimeType) {
    const blob = base64toBlob(base64Data, mimeType);

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
}

function base64toBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: mimeType });
}

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const findReceivingNodeID = (msfData) => {
    // Regular expression to find the Node Type ReceivingNode and its Node ID
    const pattern = /Node Type,ReceivingNode,.*?Node ID,(\d+),/s;

    // Search for the pattern in the data
    const match = msfData.match(pattern);

    return match ? Number(match[1]) : null;
};
