import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const Table = ({
    tableKey,
    columnNames,
    tableData,
    headerWidths,
    tableStyles,
    cellWidths = ['38%', '12%', '25%', '25%'],
    customCellWidths = {},
    title = null,
    footer = null,
}) => {
    const styles = StyleSheet.create(tableStyles);
    const headWidths = headerWidths !== undefined ? headerWidths : cellWidths;

    return (
        <View style={styles.section} wrap={false} key={tableKey}>
            {title && <Text style={styles.title}>{title}</Text>}
            {/* Table component */}
            <View style={styles.table}>
                {/* Table header */}
                <View style={styles.tableRow}>
                    {columnNames.map((name, index) => (
                        <Text
                            key={`header-${index + name}`}
                            style={{
                                ...styles.headerCell,
                                width: headWidths[index],
                            }}
                        >
                            {name}
                        </Text>
                    ))}
                </View>

                {/* Table rows */}
                {tableData.map(
                    (row, rowIndex) =>
                        row.length > 0 && (
                            <View key={rowIndex} style={styles.tableRow}>
                                {row.map((cell, cellIndex) => (
                                    <Text
                                        key={cellIndex + cell}
                                        style={{
                                            ...styles.tableCell,
                                            width: customCellWidths[rowIndex]
                                                ? customCellWidths[rowIndex][cellIndex]
                                                : cellWidths[cellIndex],
                                        }}
                                    >
                                        {cell}
                                    </Text>
                                ))}
                            </View>
                        ),
                )}
            </View>
            {footer && <Text style={styles.footer}>{footer}</Text>}
        </View>
    );
};

export default Table;
