import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFileToS3Bucket } from '../../../utils/s3Utils';
import '../css/spinner.css';
import styled from 'styled-components';
import styles from '../../auditor/Auditor.module.css';
import { convertMxProjToMsf } from '../../auditor/auditorApi';
import { Button } from 'react-bootstrap';
import { findReceivingNodeID } from '../../../utils/common-functions';

const Spinner = () => (
    <div style={{ textAlign: 'center' }}>
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
);

const DropzoneContainer = styled.div`
    background-color: #82dcca;
    color: white;
    border: 2px solid transparent;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8vw;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    transition:
        background-color 0.4s,
        color 0.4s,
        border-color 0.4s;
    height: 12vh;
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0 3vw;

    &:hover {
        background-color: white;
        color: black;
        border-color: #a3e4d7;
    }
`;

const Instructions = styled.p`
    text-align: center;
    color: #333333;

    b u {
        color: #007bff;
        text-decoration: none;
    }

    b u:hover {
        text-decoration: underline;
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

function MsfUploader({
    stateName,
    authority,
    setMsfData,
    setMsfFilePath,
    setFileName,
    setShowDownloadButton,
    handleRecordUserLogs,
    msfData,
    fileName,
    handleAuditMSF,
    handleDownloadReport,
    isAuditing,
    isAuditingWithMelody,
    setReportBlob,
    audited,
    setAudited,
    setMadeCompliantNodes,
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [isReporting, setIsReporting] = useState(false);

    const processMsf = async (msf, fileName) => {
        setMadeCompliantNodes([]);
        const msfBlob = new Blob([msf], { type: 'application/json' });
        const data = {
            state: stateName,
            authority: authority,
            msf: msf,
            receivingNodeID: findReceivingNodeID(msf),
        };

        if (data.receivingNodeID === null) {
            alert('Please specify a receiving node in your msf');
            throw new Error('No Receiving Node Specified');
        }

        setMsfData(data);
        setFileName(fileName);

        try {
            const s3Path = `msf-files-new/${fileName.replace('.msf', `-${Date.now()}.msf`)}`;
            const uploadRes = await uploadFileToS3Bucket(s3Path, 'uploaded-user-files', msfBlob);
            const uploadedFilePath = uploadRes.data.results.Key;
            setMsfFilePath(uploadedFilePath);
            handleRecordUserLogs('MSF File Uploaded', uploadedFilePath);
        } catch (error) {
            console.error('Error uploading MSF Blob:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const processMxProj = async (file) => {
        try {
            const { msf } = await convertMxProjToMsf(file);
            setReportBlob(null);
            const fileName = `${file.name.replace(/\.mxproj$/, '.msf')}`;
            await processMsf(msf, fileName);
        } catch (error) {
            setIsLoading(false);
            console.error('Error uploading file:', error);
            alert(
                "Error uploading file. Please try again or contact Mircea (0433030044) or Greg (0452518727) if the error persists.'",
            );
        }
    };

    const onDownloadReport = async () => {
        setIsReporting(true);
        await handleDownloadReport(false);
        setIsReporting(false);
    };

    const onDrop = useCallback((files) => {
        const file = files[0];
        if (
            !file.name.toLowerCase().endsWith('.msf') &&
            !file.name.toLowerCase().endsWith('.mxproj')
        ) {
            alert('Please upload an MSF or an MXPROJ file.');
            return;
        }
        setIsLoading(true);
        setReportBlob(null);
        setAudited(false);

        if (file.name.toLowerCase().endsWith('.mxproj')) {
            processMxProj(file);
            setShowDownloadButton(true);
        } else {
            setIsLoading(true);
            setShowDownloadButton(false);
            var msf = '';
            const reader = new FileReader();
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                msf = reader.result;
                processMsf(msf, file.name);
            };
            reader.readAsText(file);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const onAuditClick = () => {
        setReportBlob(null);
        handleAuditMSF(false);
    };

    const onAuditWithMelodyClick = () => {
        setReportBlob(null);

        handleAuditMSF(true);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '10px',
                }}
                className="mt-3"
            >
                <DropzoneContainer {...getRootProps()} isDragActive={isDragActive}>
                    <input {...getInputProps()} />
                    {isLoading ? (
                        <>
                            <LoadingContainer>
                                <Spinner />
                            </LoadingContainer>
                            <Instructions>Loading Model...</Instructions>
                        </>
                    ) : (
                        <div>
                            {!msfData ? (
                                <span>
                                    Click to upload your MSF / MUSIC X file or just drag and drop it
                                    here
                                </span>
                            ) : (
                                <span>{fileName}</span>
                            )}
                        </div>
                    )}
                </DropzoneContainer>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                    <Button
                        className={styles['button-medium-green']}
                        style={{ width: '15vw' }}
                        onClick={onAuditClick}
                        disabled={!msfData || isAuditingWithMelody}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isAuditing ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '10px' }}
                                />
                            ) : (
                                <div
                                    className={styles['custom-arrow']}
                                    style={{ marginRight: '10px' }}
                                />
                            )}
                            <span>
                                {isAuditing
                                    ? 'Auditing...'
                                    : ' Audit your MSF MUSIC 6 or MUSIC X file'}
                            </span>
                        </div>
                    </Button>

                    <Button
                        className={styles['button-medium-green']}
                        style={{ width: '15vw' }}
                        onClick={onAuditWithMelodyClick}
                        disabled={!msfData || isAuditing}
                    >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {isAuditingWithMelody ? (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: '10px' }}
                                />
                            ) : (
                                <div
                                    className={styles['custom-arrow']}
                                    style={{ marginRight: '10px' }}
                                />
                            )}
                            <span>Audit MSF / MUSIC X with running the model</span>
                        </div>
                    </Button>
                </div>

                <Button
                    className={styles['button-dark-green']}
                    style={{ flex: 1, margin: '0 3vw' }}
                    onClick={onDownloadReport}
                    disabled={!audited}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {isReporting ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                style={{ marginRight: '10px' }}
                            />
                        ) : (
                            <div
                                className={styles['custom-arrow']}
                                style={{ marginRight: '10px' }}
                            />
                        )}
                        <span>
                            {isReporting
                                ? 'Generating Report...'
                                : 'Click here to download your auditor report.'}
                        </span>
                    </div>
                </Button>
            </div>
        </>
    );
}

export default MsfUploader;
