// module
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDevelopmentType,
    setPlanningPermitNo,
    setProjectInfo,
} from '../utils/redux/projectInfoSlice';
import { selectUserInfo } from '../utils/redux/userInfoSlice';
import { sails_api } from '../utils/globalConstant';

function UserInfo({ projectInfo }) {
    const dispatch = useDispatch();
    const userInfo = useSelector(selectUserInfo);

    const [developmentTypes, setDevelopmentTypes] = useState([]);

    const { assessorEmail, assessorName } = userInfo;

    useEffect(() => {
        const fetchData = async () => {
            await sails_api.get('/DevelopmentTypes').then((res) => setDevelopmentTypes(res.data));
        };
        fetchData();
    }, []);

    return (
        <div
            className="d-flex flex-wrap flex-column justify-content-center align-items-center w-100 h-100 gap-3"
            style={{
                border: '3px solid #7290cf',
                borderRadius: 5,
                backgroundColor: '#cedcf8',
                fontWeight: 'bold',
                padding: '2rem 1rem',
            }}
        >
            <div className="d-flex w-100 gap-1">
                <input
                    type="text"
                    style={{ width: '50%' }}
                    className={`form-control  ${!assessorName && 'border border-danger'}`}
                    placeholder="Enter name"
                    value={assessorName}
                    disabled
                />

                <input
                    type="email"
                    style={{ width: '50%' }}
                    className={`form-control ${!assessorEmail && 'border border-danger'}`}
                    placeholder="Enter email"
                    value={assessorEmail}
                    disabled
                />
            </div>
            <div className="d-flex w-100 gap-1">
                <select
                    style={{ width: '50%' }}
                    className={`form-control ${!projectInfo.developmentType && 'border border-danger'}`}
                    name="developmentType"
                    value={projectInfo.developmentType}
                    onChange={(e) => {
                        dispatch(setDevelopmentType(e.target.value));

                        setProjectInfo({
                            planningPermitNo: projectInfo.developmentType,
                            developmentType: e.target.value,
                        });
                    }}
                    id="devType"
                >
                    <option value="" hidden>
                        Select Development Type
                    </option>
                    {developmentTypes.map((type) => (
                        <option key={type.name}>{type.name}</option>
                    ))}
                </select>
                <input
                    name="planningPermitNo"
                    style={{ width: '50%' }}
                    className={`form-control ${!projectInfo.planningPermitNo && 'border border-danger'}`}
                    value={projectInfo.planningPermitNo}
                    onChange={(e) => {
                        dispatch(setPlanningPermitNo(e.target.value));
                        setProjectInfo({
                            planningPermitNo: e.target.value,
                            developmentType: projectInfo.developmentType,
                        });
                    }}
                    placeholder="Permit Number or N/A"
                />
            </div>
        </div>
    );
}

export default UserInfo;
