import { View, Text, StyleSheet } from '@react-pdf/renderer';
import Table from '../Table';
import { addSpacesToCamelCase } from '../common';

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

const styles = StyleSheet.create({
    sectionTitle: {
        fontSize: 14,
        marginBottom: 10,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    segmentTitle: {
        fontSize: 10,
        marginBottom: 4,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
    text: {
        fontSize: 9,
        marginBottom: 4,
    },
    container: {
        padding: 0,
        marginTop: 5,
    },
    flowSegment: {
        marginTop: 10,
    },
    body: {
        paddingTop: 30,
        paddingBottom: 10,
        paddingHorizontal: 35,
        marginBottom: 10,
    },
});

export const TCCalculatorTemplate = ({
    tcData,
    createStyles,
    defaultStyles,
    devType = ['pre', 'post', 'uncontrolled'],
    headers = null,
}) => {
    return (
        <View style={styles.container} wrap={true}>
            {devType.map((devType) =>
                tcData[devType] ? (
                    <View key={devType + 'dev'} style={{ marginTop: 10 }}>
                        <View style={styles.sectionTitle} wrap={false}>
                            <Text>
                                {headers
                                    ? headers[devType]
                                    : `${addSpacesToCamelCase(capitalize(devType))} - Development Time of Concentration`}
                            </Text>
                        </View>
                        <View wrap={false} x>
                            <Text style={styles.text}>
                                {headers
                                    ? ''
                                    : `${addSpacesToCamelCase(capitalize(devType))} Development Time of Concentration = `}
                                Sum of Flow Segment Travel Times ={' '}
                                {tcData[devType].reduce((acc, calcState) => {
                                    if (calcState.result) {
                                        return acc + parseFloat(calcState.result);
                                    }
                                    return acc;
                                }, 0)}{' '}
                                minutes
                            </Text>
                            <Text style={[styles.text, { color: 'red' }]}>
                                * Summary of Flow Segment Calculations below
                            </Text>
                        </View>

                        {tcData[devType].map((segment, segmentIndex) => (
                            <View style={styles.flowSegment} key={devType + segmentIndex}>
                                <View wrap={false}>
                                    <Text style={styles.segmentTitle}>
                                        Flow Segment {segmentIndex + 1}
                                    </Text>
                                    <Text style={styles.text}>
                                        Method: {segment.selectedMethod.label}
                                    </Text>
                                </View>
                                <View>
                                    <SegmentTemplate
                                        tcSegment={segment}
                                        createStyles={createStyles}
                                        defaultStyles={defaultStyles}
                                    />
                                </View>
                            </View>
                        ))}
                    </View>
                ) : null,
            )}
        </View>
    );
};

const SegmentTemplate = ({ tcSegment, createStyles, defaultStyles }) => {

    switch (tcSegment.selectedMethod.id) {
        case 'OverlandFlowPathKinematicEquation':
            return (
                <View>
                    <View>
                        <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                            Parameter and Calculations:
                        </Text>
                        <Table
                            columnNames={[
                                'Overland Flow Catchment',
                                'Area (m²)',
                                'Surface Roughness Coefficient',
                            ]}
                            tableData={tcSegment.fields.surfaceAreas.value.map((surfaceArea) => [
                                surfaceArea.areaName,
                                surfaceArea.surfaceArea,
                                surfaceArea.surfaceRoughnessCoefficient,
                            ])}
                            cellWidths={['34%', '33%', '33%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                marginTop: 5,
                            })}
                        />
                        <Table
                            columnNames={[
                                'Average Slope (%)',
                                'Length of Flowpath (m)',
                                'Travel Time (min)',
                            ]}
                            tableData={[
                                [
                                    tcSegment.fields.slope.value,
                                    tcSegment.fields.lengthOfFlowPath.value,
                                    tcSegment.result,
                                ],
                            ]}
                            cellWidths={['34%', '33%', '33%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                tableWidth: '50%',
                                marginTop: 5,
                            })}
                        />
                    </View>
                </View>
            );
        case 'OverlandFlowPathFriendsEquation':
            return (
                <View>
                    <View>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                            Parameter and Calculations:
                        </Text>
                        <Table
                            columnNames={[
                                'Overland Flow Catchment',
                                'Area (m²)',
                                'Hotron Coefficient',
                            ]}
                            tableData={tcSegment.fields.surfaceAreas.value.map((surfaceArea) => [
                                surfaceArea.areaName,
                                surfaceArea.surfaceArea,
                                surfaceArea.hortonCoefficient,
                            ])}
                            cellWidths={['34%', '33%', '33%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                marginTop: 5,
                            })}
                        />
                        <Table
                            columnNames={[
                                'Average Slope (%)',
                                'Length of Flowpath (m)',
                                'Travel Time (min)',
                            ]}
                            tableData={[
                                [
                                    tcSegment.fields.slope.value,
                                    tcSegment.fields.lengthOfFlowPath.value,
                                    tcSegment.result,
                                ],
                            ]}
                            cellWidths={['34%', '33%', '33%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                tableWidth: '50%',
                                marginTop: 5,
                            })}
                        />
                    </View>
                </View>
            );

        case 'StandardInletTime':
        case 'TravelTimeFromRoofToMainConnection':
            return (
                <View>
                    <View>
                        <Text style={{ fontSize: 10, fontWeight: 'bold' }}>
                            Parameter and Calculations:
                        </Text>

                        <Table
                            columnNames={['Travel Time (min)']}
                            tableData={[[tcSegment.result]]}
                            cellWidths={['100%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                tableWidth: '33%',
                                marginTop: 5,
                            })}
                        />
                    </View>
                </View>
            );
        case 'Pipe/OpenChannelTravelTime-ManningsEquation':
            return (
                <View>
                    <View>
                        <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                            Parameter and Calculations:
                        </Text>
                        <Table
                            columnNames={[
                                'Manning',
                                'Average Slope (%)',
                                'Length of Pipe (m)',
                                'Pipe Diameter (m)',
                                'Travel Time (min)',
                            ]}
                            tableData={[
                                [
                                    tcSegment.fields.manning.value,
                                    tcSegment.fields.slope.value,
                                    tcSegment.fields.pipeDiameter.value,
                                    tcSegment.fields.lengthOfPipe.value,
                                    tcSegment.result,
                                ],
                            ]}
                            cellWidths={['20%', '20%', '20%', '20%', '20%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                tableWidth: '100%',
                                marginTop: 5,
                            })}
                        />
                    </View>
                </View>
            );
        case 'KerbAndChannelFlowTravelTime':
            return (
                <View>
                    <View>
                        <Text style={{ fontSize: 9, fontWeight: 'bold' }}>
                            Parameter and Calculations:
                        </Text>
                        <Table
                            columnNames={[
                                'Length of Kerb Channel Flow',
                                'Average Slope (%)',
                                'Travel Time (min)',
                            ]}
                            tableData={[
                                [
                                    tcSegment.fields.LengthofKerbChannelFlow.value,
                                    tcSegment.fields.SlopeofFlow.value,
                                    tcSegment.result,
                                ],
                            ]}
                            cellWidths={['34%', '33%', '33%']}
                            tableStyles={createStyles({
                                ...defaultStyles,
                                tableWidth: '100%',
                                marginTop: 5,
                            })}
                        />
                    </View>
                </View>
            );
        default:
            <></>;
    }
    return <></>;
};
