import { Form } from 'react-bootstrap';
import RwtCalculatorContainer from '../RwtCalculatorContainer/RwtCalculatorContainer';
import RendreDoc from '../../RenderDoc/RenderDoc';
import FormLabel from '../../FormLabel/FormLabel';
import { useDispatch } from 'react-redux';

import { isValidRealNumber } from '../../../validations/common';
import { setIndoorDemand } from '../../../utils/redux/sizeRainwaterTankToolSlice';
import rwtApi from '../rwtApi';

export default function IndoorDemandCalculator({
    isReady,
    methods,
    onMethodChange,
    calculatorState,
    setCalculatorState,
}) {
    const dispatch = useDispatch();

    const calculateIndoorDemand = async () => {
        setCalculatorState((prev) => {
            return { ...prev, errors: {} };
        });

        // Parse fields
        const parsedData = {};
        const errors = {};
        setCalculatorState({ ...calculatorState, errors });
        for (const fieldKey in calculatorState.fields) {
            const field = calculatorState.fields[fieldKey];

            if (field.disabled) continue;

            // Add value to body if it's valid
            if (isValidRealNumber(field.value, 2)) parsedData[fieldKey] = parseFloat(field.value);
            // Set error
            else {
                errors[fieldKey] =
                    field.value.length === 0 ? 'Input field cannot be empty' : 'Invalid value';
                setCalculatorState({ ...calculatorState, errors });
            }
        }

        // console.log('[ERRORS]', errors);

        if (Object.keys(errors).length > 0) {
            setCalculatorState((prev) => {
                return { ...prev, errors, status: 'initial' };
            });
            return;
        } else {
            setCalculatorState((prev) => {
                return { ...prev, errors, status: 'inProgress' };
            });
        }

        // Prepare payload
        const body = { method: calculatorState.selectedMethod.id, params: {} };
        body.params = parsedData;
        console.log('[BODY]', body);

        // Send request to backend to perform calculations
        rwtApi
            .calculateIndoorDemand(body)
            .then((result) => {
                console.log('[INDOOR_DEMAND_RESULT]', result);
                // Set result
                setCalculatorState((prev) => {
                    return { ...prev, errors: {}, result, status: 'initial' };
                });
                dispatch(setIndoorDemand(result));
            })
            .catch((error) => {
                // TODO: what should happen in an error
                alert(error.message);
                setCalculatorState((prev) => {
                    return { ...prev, status: 'initial' };
                });
            });
    };

    const onInputFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        const updatedState = { ...calculatorState };
        // Modify state
        if (value.length === 0 || (value.length > 0 && isValidRealNumber(value, 2)))
            updatedState.fields[name].value = value;
        updatedState.result = null;
        // Update state
        setCalculatorState(updatedState);
    };

    //-------------------------------------------
    // Set Method Doc Section
    //-------------------------------------------
    let methodDoc = null;
    if (calculatorState.selectedMethod?.info?.blocks)
        methodDoc = (
            <div className="doc-container">
                <RendreDoc blocks={calculatorState.selectedMethod?.info?.blocks} />
            </div>
        );

    //-------------------------------------------
    // Set Form
    //-------------------------------------------
    const form = (fields) => {
        const calculatorType = 'indoor';

        return (
            <form className="rwt-calculator-form">
                {Object.keys(fields).map((fieldId) => {
                    const field = fields[fieldId];
                    return (
                        <div key={field.id} className="input-control-container">
                            <FormLabel htmlFor={`${calculatorType}-${field.id}`} info={field.info}>
                                {field.label}
                            </FormLabel>
                            <div>
                                <Form.Control
                                    id={`${calculatorType}-${field.id}`}
                                    name={`${field.id}`}
                                    disabled={field?.disabled}
                                    value={field.value}
                                    onChange={(e) => onInputFieldChange(e)}
                                />
                                <span className="input-control-error">
                                    {calculatorState.errors[field.id]}
                                </span>
                            </div>
                        </div>
                    );
                })}
            </form>
        );
    };

    //-------------------------------------------
    // Component
    //-------------------------------------------
    return (
        <RwtCalculatorContainer
            isReady={isReady}
            backgroundColor="#59cce128"
            title="Indoor Demand Calculator"
            methods={methods}
            selectedMethod={calculatorState.selectedMethod?.id}
            onMethodChange={(method) => onMethodChange(method, calculatorState, setCalculatorState)}
            result={{ label: 'Total Demand, kL/day', value: calculatorState.result }}
            inProgress={calculatorState.status === 'inProgress'}
            onSubmit={calculateIndoorDemand}
        >
            {methodDoc}
            {form(calculatorState.fields)}
        </RwtCalculatorContainer>
    );
}
