import React, { useContext } from 'react';
import styles from './LiveResultRow.module.css';
import {
    TooltipContainer,
    TooltipContent,
    TooltipButton,
} from '../../../../styled-components/TooltipComponents';

const LiveResultRow = ({ result, resultUpdateRequired, area }) => {
    const formattedResult = {
        tn: result?.stormRatingPercTN?.toFixed(1),
        tp: result?.stormRatingPercTP?.toFixed(1),
        tss: result?.stormRatingPercTSS?.toFixed(1),
        gp: result?.stormRatingPercGP?.toFixed(1),
        fr: result?.reductionPercFlow?.toFixed(1),
        wsr: result?.waterSupplyReliability?.toFixed(1),
        sf: result?.sourceFlow?.toFixed(5),
        wr:
            ((area?.numberOfOccupants * 62 * 365) / 1000) *
            ((result?.waterSupplyReliability ?? 0) / 100),
    };
    const getColor = (currValue) => {
        return currValue <= 0 ? 'black' : currValue >= 100 ? 'green' : 'red';
    };

    return (
        <>
            <div className={styles.liveStormRow}>
                <div className={styles.resultLabel}>
                    LIVE STORM Result
                    <TooltipContainer>
                        <TooltipButton>❓</TooltipButton>
                        <TooltipContent $width="24vw" $position="top-right">
                            <span>
                                These results correspond to the catchment above and can be employed
                                to fine-tune treatment.
                            </span>
                        </TooltipContent>
                    </TooltipContainer>
                </div>

                {!resultUpdateRequired ? (
                    result?.alert ? (
                        <div className={styles['alert']}>{result.alert}</div>
                    ) : (
                        <div
                            className={`${styles.resultsWrapper} ${
                                result === null && styles.hidden
                            }`}
                        >
                            <div className={styles.result}>
                                <span
                                    style={{
                                        color: getColor(formattedResult['tn']),
                                    }}
                                >
                                    TN: <span>{formattedResult['tn'] ?? 'N/A'}</span>
                                </span>

                                <span
                                    style={{
                                        color: getColor(formattedResult['tp']),
                                    }}
                                >
                                    TP: <span>{formattedResult['tp'] ?? 'N/A'}</span>
                                </span>

                                <span
                                    style={{
                                        color: getColor(formattedResult['tss']),
                                    }}
                                >
                                    TSS: <span>{formattedResult['tss'] ?? 'N/A'}</span>
                                </span>

                                <span
                                    style={{
                                        color: getColor(formattedResult['gp']),
                                    }}
                                >
                                    GP: <span>{formattedResult['gp'] ?? 'N/A'}</span>
                                </span>
                            </div>
                            <div className={styles.result}>
                                <span>
                                    Source (KL/yr):{' '}
                                    {(formattedResult['sf'] * 1000).toFixed(1) ?? 'N/A'}
                                </span>
                                <span>Flow Reduction (%): {formattedResult['fr'] ?? 'N/A'}</span>
                                {formattedResult['wsr'] && (
                                    <span>RWT Reliability (%): {formattedResult['wsr']}</span>
                                )}
                                {formattedResult['wsr'] && (
                                    <span>
                                        Vol Reused (KL/yr):{' '}
                                        {formattedResult['wr'].toFixed(2) ?? 'N/A'}
                                    </span>
                                )}
                            </div>
                            {result?.warnings &&
                                !resultUpdateRequired &&
                                result?.warnings.map((warning, index) => (
                                    <div
                                        className={`${styles.liveStormRow} ${styles.warning}`}
                                        key={'warning-' + index}
                                    >
                                        {warning}
                                    </div>
                                ))}
                        </div>
                    )
                ) : (
                    <div className={styles.loading}>Calculating...</div>
                )}
            </div>
        </>
    );
};

export default LiveResultRow;
