import React from 'react';
import { FaCalculator } from 'react-icons/fa';

// components
import FormRow from './FormRow';

import TCSImage from '../../../assets/tooltip-images/tc.png';
// utils
import { isFloat } from '../../../utils/common';

const MultiplicationDesignStormTC = ({
    globalInputs,
    setGlobalInputs,
    AEPS,
    setShowTCCalculator,
    setTcCalculatorData,
}) => {
    const handleInputChange = (e, _, numberCheck = false) => {
        let { name, value } = e.target;

        if (numberCheck && !isFloat(e.target.value)) {
            return;
        }
        const bufArray = { ...globalInputs };
        bufArray[name] = value;

        setGlobalInputs(bufArray);
        setTcCalculatorData((prev) => ({
            ...prev,
            [name]: null,
        }));
    };

    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2 span-2">Design Storm</span>
                <span className="title-2 span-3">Time of Concentration</span>

                {/* <div className="title-2 span-3">Time of Concentration</div> */}
            </div>
            <FormRow
                formRow={[globalInputs]}
                columns={[
                    // {
                    //     id: 'qaMultiplicationFactor',
                    //     label: '',
                    //     inputType: 'dropdown',
                    //     options: [
                    //         { label: 'Swinburne OSD4w', value: 2 },
                    //         { label: 'Standard Swinburne', value: 1 },
                    //     ],
                    //     className: 'span-2',
                    // },
                    // null,
                    {
                        id: 'aepPSD',
                        label: 'PSD (% AEP)',
                        inputType: 'dropdown',
                        options: AEPS.map((aep) => ({ label: aep, value: aep })),
                    },
                    {
                        id: 'aepOSD',
                        label: 'OSD (% AEP)',
                        inputType: 'dropdown',
                        options: AEPS.map((aep) => ({ label: aep, value: aep })),
                    },
                    {
                        id: 'catchmentTimeTCS',
                        label: 'TCS (mins):',
                        numberCheck: true,
                        labelToolTip: {
                            content: (
                                <div>
                                    <img src={TCSImage} style={{ width: '100%' }} />
                                    <p className="mt-1">
                                        Council should know and provide these values. If council
                                        does not provide them, please do an analysis of the entire
                                        catrchment or choose another method
                                    </p>
                                </div>
                            ),
                        },
                    },
                    {
                        id: 'tcCalculator',
                        inputType: 'button',
                        icon: <FaCalculator />,
                        numberCheck: true,
                        onClick: () => setShowTCCalculator('catchmentTimeTCS'),
                        title: 'Calculate TC',
                    },
                    {
                        id: 'catchmentTimeTSO',
                        label: 'TSO (mins):',
                        numberCheck: true,
                        labelToolTip: {
                            content: (
                                <div>
                                    <img src={TCSImage} style={{ width: '100%' }} />
                                    <p className="mt-1">
                                        Council should know and provide these values. If council
                                        does not provide them, please do an analysis of the entire
                                        catrchment or choose another method
                                    </p>
                                </div>
                            ),
                        },
                    },
                    {
                        id: 'tsoPreCalculator',
                        inputType: 'button',
                        icon: <FaCalculator />,
                        numberCheck: true,
                        onClick: () => setShowTCCalculator('catchmentTimeTSO'),
                        title: 'Calculate TC',
                    },
                    {
                        id: 'catchmentTimeTC',
                        label: 'TC (mins):',
                        disabled: true,
                        labelToolTip: {
                            content: (
                                <div>
                                    <img src={TCSImage} style={{ width: '100%' }} />
                                    <p className="mt-1">
                                        Council should know and provide these values. If council
                                        does not provide them, please do an analysis of the entire
                                        catrchment or choose another method
                                    </p>
                                </div>
                            ),
                        },
                    },
                ]}
                handleInputChange={handleInputChange}
            />
        </div>
    );
};

export default MultiplicationDesignStormTC;
