export const data = {
    assessorName: 'Hamza Qureshi',
    assessorEmail: 'hamza@cleanstormwater.com.au',
    userID: '0aacdda3-77cf-4077-bcb9-d4eb3eae99a5',
    reportHeaderIcon:
        'https://company-icon.s3.ap-southeast-2.amazonaws.com/totoev-logo.png?AWSAccessKeyId=AKIA3KEXQBBH4W5IMG66&Expires=1736472743&Signature=%2FGAoKh2EknlWf6RrazTp33%2Bxz4c%3D&X-Amzn-Trace-Id=Root%3D1-677dd5a7-2a3901b414047aa12384aaa3%3BParent%3D34a59fe063fc6223%3BSampled%3D0%3BLineage%3D1%3A58313640%3A0',
    developmentType: 'Commercial/Retail',
    planningPermitNo: '12',
    projectID: 27969,
    state: 'VIC',
    council: 'Ballarat City',
    rainfallStation: 'Ballarat',
    suburb: 'Ballarat Central',
    postcode: '3350',
    address: '301 Eyre St, Ballarat Central VIC 3350, Australia',
    coordinate: {
        lat: '-37.5657101',
        lng: '143.8505523',
    },
    latitude: '-37.5657101',
    longitude: '143.8505523',
    osdResult: {
        A: 2700,
        i_PSD: 49.10457224277168,
        Cpre: 0.4111111111111111,
        Cu: 0,
        Qu: 0,
        Qp: 15.14057644152127,
        Cpost: 0.7666666666666667,
        i_OSD: 56.49906110771949,
        Qa: 56.47025807918744,
        Qa_dash: 64.97392027387741,
        td: 20.400000000000023,
        PSD: 18.016812098298665,
        onSiteDetentionVolume: 22.763461002671214,
        orificeDiameter: 87.35799411868915,
    },
    osd: {
        OSDs4VIPs: true,
        method: 'Swinburne Method',
        psd: '',
        councilPSD: '',
        preDevAreas: {
            areas: [
                {
                    id: 0,
                    areaName: 'Area A',
                    impervious: true,
                    surfaceArea: '1200',
                    runoffCoefficient: '0.3',
                },
                {
                    id: 1,
                    areaName: 'Area B',
                    impervious: true,
                    surfaceArea: '1500',
                    runoffCoefficient: '0.5',
                },
            ],
        },
        postDevAreas: {
            areas: [
                {
                    id: 0,
                    areaName: 'Area C',
                    impervious: true,
                    surfaceArea: '2000',
                    surfaceType: 'Paved',
                    uncontrolled: '0',
                    runoffCoefficient: '1',
                },
                {
                    id: 1,
                    areaName: 'Area D',
                    impervious: true,
                    surfaceArea: '700',
                    uncontrolled: '0',
                    runoffCoefficient: '0.1',
                },
            ],
        },
        catchmentTimeTc: 17.91,
        catchmentTimeTso: 12.82,
        catchmentTimeTcs: 5.09,
        tankHeight: '1.2',
        aepPSD: 20,
        aepOSD: 10,
        climateAdjustment: 1.12,
        climateChangeRate: '12',
        qaMultiplicationFactor: '2',
        sumPreDevAreas: 2700,
        sumPostDevAreas: 2700,
        sumUncontrolledAreas: 0,
        tcData: {
            catchmentTimeTCS: [
                {
                    errors: {},
                    fields: {
                        SlopeofFlow: {
                            id: 'SlopeofFlow',
                            info: null,
                            label: 'Slope of Flowpath (%)',
                            order: 2,
                            value: '12',
                            defaultValue: null,
                        },
                        LengthofKerbChannelFlow: {
                            id: 'LengthofKerbChannelFlow',
                            info: null,
                            label: 'Length of Kerb Channel Flow (m)',
                            order: 1,
                            value: '12',
                            defaultValue: null,
                        },
                    },
                    result: '0.09',
                    selectedMethod: {
                        id: 'KerbAndChannelFlowTravelTime',
                        info: null,
                        rows: [
                            {
                                id: 'LengthofKerbChannelFlow',
                                info: null,
                                order: 1,
                                fields: [
                                    {
                                        id: 'LengthofKerbChannelFlow',
                                        info: null,
                                        label: 'Length of Kerb Channel Flow (m)',
                                        order: 1,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'SlopeofFlow',
                                info: null,
                                order: 2,
                                fields: [
                                    {
                                        id: 'SlopeofFlow',
                                        info: null,
                                        label: 'Slope of Flowpath (%)',
                                        order: 2,
                                        defaultValue: null,
                                    },
                                ],
                            },
                        ],
                        label: 'Kerb And Channel Flow Travel Time',
                        tooltip: {
                            time: 1719920191398,
                            blocks: [
                                {
                                    id: '7iPqD4j775',
                                    data: {
                                        file: {
                                            url: 'https://tc-calculator.s3.ap-southeast-2.amazonaws.com/1719920189701-Kerb Channel Info.PNG',
                                        },
                                        caption: '',
                                        stretched: false,
                                        withBorder: false,
                                        withBackground: false,
                                    },
                                    type: 'image',
                                },
                            ],
                            version: '2.29.1',
                        },
                    },
                },
                {
                    errors: {},
                    fields: {
                        standardInletTime: {
                            id: 'standardInletTime',
                            info: {
                                link: null,
                                blocks: [
                                    {
                                        id: 'vgQVtmNNMI',
                                        data: {
                                            file: {
                                                url: 'https://tc-calculator.s3.ap-southeast-2.amazonaws.com/1725271291378-STD inlet time 2.PNG',
                                            },
                                            caption: '',
                                            stretched: false,
                                            withBorder: false,
                                            withBackground: false,
                                        },
                                        type: 'image',
                                    },
                                ],
                            },
                            label: 'Standard Inlet Time (min)',
                            order: 1,
                            value: 5,
                            defaultValue: 5,
                        },
                    },
                    result: '5.00',
                    selectedMethod: {
                        id: 'StandardInletTime',
                        info: {
                            time: 1725271238866,
                            blocks: [
                                {
                                    id: 'Wtu8evNSX9',
                                    data: {
                                        file: {
                                            url: 'https://tc-calculator.s3.ap-southeast-2.amazonaws.com/1725271220263-STD Inlet time.PNG',
                                        },
                                        caption: 'Source: Queensland Urban Drainage Manual, 2013',
                                        stretched: false,
                                        withBorder: false,
                                        withBackground: false,
                                    },
                                    type: 'image',
                                },
                            ],
                            version: '2.29.1',
                        },
                        rows: [
                            {
                                id: 'standardInletTime',
                                info: null,
                                order: 1,
                                fields: [
                                    {
                                        id: 'standardInletTime',
                                        info: {
                                            link: null,
                                            blocks: [
                                                {
                                                    id: 'vgQVtmNNMI',
                                                    data: {
                                                        file: {
                                                            url: 'https://tc-calculator.s3.ap-southeast-2.amazonaws.com/1725271291378-STD inlet time 2.PNG',
                                                        },
                                                        caption: '',
                                                        stretched: false,
                                                        withBorder: false,
                                                        withBackground: false,
                                                    },
                                                    type: 'image',
                                                },
                                            ],
                                        },
                                        label: 'Standard Inlet Time (min)',
                                        order: 1,
                                        defaultValue: 5,
                                    },
                                ],
                            },
                        ],
                        label: 'Standard Inlet Time',
                        tooltip: null,
                    },
                },
            ],
            catchmentTimeTSO: [
                {
                    errors: {},
                    fields: {
                        slope: {
                            id: 'slope',
                            info: null,
                            label: 'Slope (%)',
                            order: 2,
                            value: '3',
                            defaultValue: null,
                        },
                        manning: {
                            id: 'manning',
                            info: null,
                            label: 'Manning',
                            order: 1,
                            value: '10',
                            defaultValue: null,
                        },
                        lengthOfPipe: {
                            id: 'lengthOfPipe',
                            info: null,
                            label: 'Length Of Pipe (m)',
                            order: 4,
                            value: '12',
                            defaultValue: null,
                        },
                        pipeDiameter: {
                            id: 'pipeDiameter',
                            info: null,
                            label: 'Pipe Diameter (mm)',
                            order: 3,
                            value: '120',
                            defaultValue: null,
                        },
                    },
                    result: '11.96',
                    selectedMethod: {
                        id: 'Pipe/OpenChannelTravelTime-ManningsEquation',
                        info: null,
                        rows: [
                            {
                                id: 'manning',
                                info: null,
                                order: 1,
                                fields: [
                                    {
                                        id: 'manning',
                                        info: null,
                                        label: 'Manning',
                                        order: 1,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'slope',
                                info: null,
                                order: 2,
                                fields: [
                                    {
                                        id: 'slope',
                                        info: null,
                                        label: 'Slope (%)',
                                        order: 2,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'pipeDiameter',
                                info: null,
                                order: 3,
                                fields: [
                                    {
                                        id: 'pipeDiameter',
                                        info: null,
                                        label: 'Pipe Diameter (mm)',
                                        order: 3,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'lengthOfPipe',
                                info: null,
                                order: 4,
                                fields: [
                                    {
                                        id: 'lengthOfPipe',
                                        info: null,
                                        label: 'Length Of Pipe (m)',
                                        order: 4,
                                        defaultValue: null,
                                    },
                                ],
                            },
                        ],
                        label: 'Pipe/Open Channel Travel Time - Mannings Equation',
                        tooltip: null,
                    },
                },
                {
                    errors: {},
                    fields: {
                        slope: {
                            id: 'slope',
                            info: null,
                            label: 'Slope, S, m/m',
                            order: 2,
                            value: '34',
                            defaultValue: null,
                        },
                        manning: {
                            id: 'manning',
                            info: null,
                            label: 'manning N',
                            order: 1,
                            value: '12',
                            defaultValue: null,
                        },
                        baseWidth: {
                            id: 'baseWidth',
                            info: null,
                            label: 'Base Width, m',
                            order: 4,
                            value: '43',
                            defaultValue: null,
                        },
                        waterDepth: {
                            id: 'waterDepth',
                            info: null,
                            label: 'Water Depth, m',
                            order: 6,
                            value: '3',
                            defaultValue: null,
                        },
                        batterSlope: {
                            id: 'batterSlope',
                            info: null,
                            label: 'Batter Slope, 1 in X',
                            order: 5,
                            value: '44',
                            defaultValue: null,
                        },
                        lengthOfChannel: {
                            id: 'lengthOfChannel',
                            info: null,
                            label: 'Length of Channel',
                            order: 3,
                            value: '33',
                            defaultValue: null,
                        },
                    },
                    result: '0.86',
                    selectedMethod: {
                        id: 'TrapezoidChannelTravelTimeManningEquation',
                        info: null,
                        rows: [
                            {
                                id: 'manning',
                                info: null,
                                order: 1,
                                fields: [
                                    {
                                        id: 'manning',
                                        info: null,
                                        label: 'manning N',
                                        order: 1,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'slope',
                                info: null,
                                order: 2,
                                fields: [
                                    {
                                        id: 'slope',
                                        info: null,
                                        label: 'Slope, S, m/m',
                                        order: 2,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'lengthOfChannel',
                                info: null,
                                order: 3,
                                fields: [
                                    {
                                        id: 'lengthOfChannel',
                                        info: null,
                                        label: 'Length of Channel',
                                        order: 3,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'baseWidth',
                                info: null,
                                order: 4,
                                fields: [
                                    {
                                        id: 'baseWidth',
                                        info: null,
                                        label: 'Base Width, m',
                                        order: 4,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'batterSlope',
                                info: null,
                                order: 5,
                                fields: [
                                    {
                                        id: 'batterSlope',
                                        info: null,
                                        label: 'Batter Slope, 1 in X',
                                        order: 5,
                                        defaultValue: null,
                                    },
                                ],
                            },
                            {
                                id: 'waterDepth',
                                info: null,
                                order: 6,
                                fields: [
                                    {
                                        id: 'waterDepth',
                                        info: null,
                                        label: 'Water Depth, m',
                                        order: 6,
                                        defaultValue: null,
                                    },
                                ],
                            },
                        ],
                        label: 'Trapezoid Channel Travel Time - Manning Equation',
                        tooltip: null,
                    },
                },
            ],
            catchmentTimeTCpre: null,
            catchmentTimeTSOpost: null,
        },
    },
    storageType: 'Below Ground Tank',
    OSDCompany: 'Waterline Tanks',
    OSDMaterial: 'Steel',
    reportHeaderIconUrl:
        'https://company-icon.s3.ap-southeast-2.amazonaws.com/totoev-logo.png?AWSAccessKeyId=AKIA3KEXQBBH4W5IMG66&Expires=1736472743&Signature=%2FGAoKh2EknlWf6RrazTp33%2Bxz4c%3D&X-Amzn-Trace-Id=Root%3D1-677dd5a7-2a3901b414047aa12384aaa3%3BParent%3D34a59fe063fc6223%3BSampled%3D0%3BLineage%3D1%3A58313640%3A0',
};
