import axios from 'axios';

const baseUrl = 'https://xgr5xf78jh.execute-api.ap-southeast-2.amazonaws.com/tc-calculator';

const endpoint = {
    occupancyCalculator: 'occupancyCalculator',
    indoorDemandCalculator: 'indoorDemandCalculator',
    toiletDemandCalculator: 'toiletDemandCalculator',
    laundryDemandCalculator: 'laundryDemandCalculator',
    irrigationCalculator: 'irrigationCalculator',
};

const obj = {};

/**
 * @param {Object} data
 * @param {string} data.method
 * @param {Object} data.values
 * @param {number} data.params.totalFloorArea
 * @param {number} data.params.occupancyRate
 * @param {number} data.params.occupancyHoursProfile
 *
 * @returns {Promise<number>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.calculateTCForMethod = async (data) => {
    return await axios
        .post(`${baseUrl}?endpoint=calculate`, data)
        .then((res) => {
            return res.data.result.travelTime.toFixed(2);
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the range of 2xx
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contact support.');
            } else {
                throw Error("Calculation couldn't be perfomred! Please contact support.");
            }
        });
};

/**
 * Get methods, fields, documentation for each tool from the server
 *
 * @returns {Promise<Object>}
 * @throws {Error} Problems during sending the HTTP request
 */
obj.getCalculators = async () => {
    // TODO: Get data from backend
    return await axios
        .get(`${baseUrl}`)
        .then((res) => {
            const calculators = {};
            res.data.result.forEach((calculator) => {
                calculators[calculator.id] = calculator;
            });
            return calculators;
        })
        .catch((error) => {
            if (error.response) {
                // The request was made and the server responded with a status code out of the rnage of 2xx
                console.log(error.response.data);
                throw Error('Something wrong happend!');
            } else if (error.request) {
                // The request was made but no response was received
                throw Error('Server is unreachable! Please contact support.');
            } else {
                throw Error("Calculation couldn't be performed! Please contact support.");
            }
        });
};

export default obj;
