import React, { useState, useEffect } from 'react';
import ResultsTable from '../Calculator/WSUD/OverallResultsSections/ResultsTable';
import { getTargetReductionsAndWqMethod } from '../../utils/api/lambda';

import styles from './Auditor.module.css';

const AuditorMelodyResults = ({ state, cityCouncil, rainfallStation, overallReductions }) => {
    const [targetReductions, setTargetReductions] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            getTargetReductionsAndWqMethod(state, rainfallStation)
                .then((res) => setTargetReductions(res[0]))
                .catch((error) => {
                    console.error(error);
                });
        };
        fetchData();
    }, [state, cityCouncil]);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '2rem 0',
            }}
        >
            <div className={styles['reductionTable']} name="reductionTable">
                {targetReductions !== null && (
                    <ResultsTable
                        title="Overall Reductions"
                        header={['', 'TN (%)', 'TP (%)', 'TSS (%)', 'GP (%)', 'Flow (%)']}
                        results={[
                            [
                                'Achieved Reductions',
                                overallReductions['Total Nitrogen (kg/yr)'][2].toFixed(2),
                                overallReductions['Total Phosphorus (kg/yr)'][2].toFixed(2),
                                overallReductions['Total Suspended Solid (kg/yr)'][2].toFixed(2),
                                overallReductions['Total Gross Polutent (kg/yr)'][2].toFixed(2),
                                overallReductions['Flow (ML/yr)'][2].toFixed(2),
                            ],
                            [
                                'LGA Target Reductions',
                                targetReductions?.tn,
                                targetReductions?.tp,
                                targetReductions?.tss,
                                targetReductions?.gp,
                                'N/A',
                            ],
                            ['Greenstar Credit Achievement', 45, 65, 85, 90, 'N/A'],
                            ['Greenstar Exeptional Performance', 60, 70, 90, 95, 'N/A'],
                        ]}
                        greenOrRed={{
                            '01':
                                parseFloat(overallReductions['Total Nitrogen (kg/yr)'][0]) >
                                targetReductions.tn,
                            '02':
                                parseFloat(overallReductions['Total Phosphorus (kg/yr)'][0]) >
                                targetReductions.tp,
                            '03':
                                parseFloat(overallReductions['Total Suspended Solid (kg/yr)'][0]) >
                                targetReductions.tss,
                            '04':
                                parseFloat(overallReductions['Total Gross Polutent (kg/yr)'][0]) >
                                targetReductions.gp,
                        }}
                        margin="0vh"
                    />
                )}
            </div>
        </div>
    );
};

export default AuditorMelodyResults;
