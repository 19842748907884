// modules
import React, { useState, useEffect, useRef } from 'react';
import { Alert } from 'react-bootstrap';

// utils
import { pipeCalc, tankCalc } from '../../../utils/idmTemplateUtils';

// css
import './IdmTemplate.css';
import IfdDataset from '../components/IfdDataset';
// components
import OsdsAds from '../osds-ads/OsdsAds';
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../styled-components/TooltipComponents';
import { StorageTypeSelection } from '../StorageTypeSelection';
import SiteArea from '../components/SiteArea';
import Optional from '../components/Optional';
import CalculateOsdResultButton from '../components/CalculateOsdResultButton';
import OsdResultsTable from '../components/OsdResultTable';
import { getCompanyDetails } from '../../../utils/osdUtils';
import { recordCalculateOSD } from '../../../utils/s3Utils';
import Tooltip from '../../common/Tooltip/Tooltip';
import { useSelector } from 'react-redux';
import { osdState } from '../../../utils/redux/osdStateDTSlice';
export default function IdmTemplate({
    useOSDCompany,
    useStorageType,
    setOSDMaterial,
    userInfo,
    mapInfo,
    useOSDResultsData,
    setTemplateData,
    savedModel,
}) {
    const isInitialMount = useRef(true);

    const [osdCompany, setOSDCompany] = useOSDCompany;
    const [storageType, setStorageType] = useStorageType;
    const [osdResultData, setOsdResultData] = useOSDResultsData;
    const [companyDetails, setCompanyDetails] = useState([]);
    const state = useSelector(osdState);

    /* Template Data */
    const [siteArea, setSiteArea] = useState({
        totalSiteArea: savedModel?.totalSiteArea ?? '',
        imperviousArea: savedModel?.siteAreaDrainingToOSD ?? '',
    });
    const [selectedDevelopment, setSelectedDevelopment] = useState(
        savedModel?.selectedDevelopment ?? 'Multi-unit development (newer residential areas)',
    );
    const [allowableDischargeRate, setAllowableDischargeRate] = useState(
        savedModel?.allowableDischargeRate ?? 65,
    );
    const [storageRequired, setStorageRequired] = useState(
        savedModel?.storageRequired ?? {
            vertical: 8,
            horizontal: 10,
        },
    );
    // optional
    const [climateChangeRate, setClimateChangeRate] = useState(savedModel?.climateAdjustment ?? '');
    const [tankHeight, setTankHeight] = useState(savedModel?.tankHeight ?? '');
    /* Validation */
    const showTotalAreaSmallerThanImperviousAreaWarning =
        +siteArea.totalSiteArea < +siteArea.imperviousArea;
    // loading and validation
    const [osdResultLoading, setOsdResultLoading] = useState(false);

    useEffect(() => {
        if (storageType !== '') {
            getCompanyDetails(storageType, mapInfo.rainfallStation).then((newCompanyDetails) => {
                if (!newCompanyDetails.length) {
                    setStorageType('N/A');
                    setOSDMaterial('N/A');
                }
                setCompanyDetails(newCompanyDetails);
            });
        }
    }, [storageType]);

    // click on CALCULATE  OSD button
    const computeOSDResults = async () => {
        setTemplateData(null);
        setOsdResultLoading(true);
        setOsdResultData(null);

        tankCalc(
            siteArea.imperviousArea,
            allowableDischargeRate,
            storageRequired,
            1 + climateChangeRate / 100,
            tankHeight,
        ).then((res1) => {
            if (res1 === 'Oppps! Something wrong, please try again!') {
                alert(res1);
                setOsdResultLoading(false);
                return;
            }

            pipeCalc(
                siteArea.imperviousArea,
                allowableDischargeRate,
                storageRequired,
                1 + climateChangeRate / 100,
                tankHeight,
                state.deltaT,
            ).then((res2) => {
                if (res2 === 'Oppps! Something wrong, please try again!') {
                    alert(res2);
                    setOsdResultLoading(false);
                    return;
                }

                const res = storageType === 'Below Ground Pipe' ? res2 : res1;
                setOsdResultData({
                    ...osdResultData,
                    tankCalcResult: {
                        onSiteDetentionVolume: res1.onSiteDetentionVolume
                            ? +res1.onSiteDetentionVolume.toFixed(3)
                            : 0,
                        orificeDiameter: res1.orificeDiameter
                            ? +res1.orificeDiameter.toFixed(3)
                            : 0,
                        permissionDischarge: res1.permissionDischarge
                            ? +res1.permissionDischarge.toFixed(3)
                            : 0,
                    },
                    pipeCalcResult: {
                        onSiteDetentionVolume: res2.onSiteDetentionVolume
                            ? +res2.onSiteDetentionVolume.toFixed(3)
                            : 0,
                        orificeDiameter: res2.orificeDiameter
                            ? +res2.orificeDiameter.toFixed(3)
                            : 0,
                        permissionDischarge: +res2.permissionDischarge
                            ? +res2.permissionDischarge.toFixed(3)
                            : 0,
                    },
                });

                recordCalculateOSD({
                    name: userInfo.assessorName,
                    email: userInfo.assessorEmail,
                    coordinates: `${mapInfo.coordinate.lat}, ${mapInfo.coordinate.lng}`,
                    catchment_area: siteArea.totalSiteArea,
                    council: mapInfo.council,
                    address: mapInfo.address,
                    storage_type: storageType,
                    tank_specified: osdCompany,
                    psd: res.permissionDischarge,
                    volume: res.onSiteDetentionVolume,
                    height_above_orifice: tankHeight,
                    orifice_diameter: res.orificeDiameter,
                });

                setOsdResultLoading(false);

                setTemplateData({
                    OSDs4VIPs: true,
                    method: 'IDM Method',
                    totalSiteArea: siteArea.totalSiteArea,
                    siteAreaDrainingToOSD: siteArea.imperviousArea,
                    selectedDevelopment: selectedDevelopment,
                    allowableDischargeRate: allowableDischargeRate,
                    storageRequired: storageRequired,
                    storageRequiredCalculated:
                        storageType === 'Above ground storage' ||
                        storageType === 'Below ground tank'
                            ? (+storageRequired.vertical * +siteArea.imperviousArea) / 1000
                            : (+storageRequired.horizontal * +siteArea.imperviousArea) / 1000,
                    climateAdjustment: climateChangeRate,
                    tankHeight: tankHeight,
                });
            });
        });
    };

    /* Reset OSD Data */
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }
        const resetOSDData = () => {
            setOsdResultData(null);
            setTemplateData(null);
        };

        resetOSDData();
    }, [siteArea, selectedDevelopment, climateChangeRate, tankHeight]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <div style={{ width: '75%' }}>
                    <span className="text-danger">
                        OSD results are produced using the Authority's OSD Table Method.
                        <Tooltip link="https://www.designmanual.com.au/assets/files/documents/IDM/IDM_Version_5.4_.pdf">
                            <span>view more details</span>
                        </Tooltip>
                        {/* <TooltipContainer> */}
                        {/*     <TooltipButton> */}
                        {/*         <a */}
                        {/*             href="https://www.designmanual.com.au/assets/files/documents/IDM/IDM_Version_5.4_.pdf" */}
                        {/*             target="_blank" */}
                        {/*             rel="noreferrer" */}
                        {/*         > */}
                        {/*             <span */}
                        {/*                 data-tip="view more details" */}
                        {/*                 data-multiline="true" */}
                        {/*                 data-place="bottom" */}
                        {/*             > */}
                        {/*                 ❓ */}
                        {/*             </span> */}
                        {/*         </a> */}
                        {/*     </TooltipButton> */}
                        {/*     <TooltipContent> */}
                        {/*         <span>view more details</span> */}
                        {/*     </TooltipContent> */}
                        {/* </TooltipContainer> */}
                    </span>
                </div>
                <div style={{ width: '45%', textAlign: 'right', marginRight: '1%' }}>
                    {' '}
                    {/* Set width for IfdDataset */}
                    <IfdDataset setOsdResultData={setOsdResultData} />
                </div>
            </div>
            <div></div>
            <br />
            {/* User Input Form */}
            <SiteArea siteArea={siteArea} setSiteArea={setSiteArea} />
            <br />
            <Alert variant="danger" show={showTotalAreaSmallerThanImperviousAreaWarning}>
                ⚠️ Please ensure impervious areas do not exceed total site area
            </Alert>
            <br />
            {/* Development Type Table */}
            <IdmDevelopmentTypeTable
                selectedDevelopment={selectedDevelopment}
                setSelectedDevelopment={setSelectedDevelopment}
                setAllowableDischargeRate={setAllowableDischargeRate}
                setStorageRequired={setStorageRequired}
            />
            <br />
            {/* optional */}
            <Optional
                climateChangeRate={climateChangeRate}
                setClimateChangeRateChange={setClimateChangeRate}
                tankHeight={tankHeight}
                setTankHeight={setTankHeight}
            />
            <br />
            {/* Result Table */}
            <div>
                <StorageTypeSelection setStorageType={setStorageType} />
            </div>
            {storageType && companyDetails && (
                <>
                    <br />
                    <OsdsAds
                        companyDetails={companyDetails}
                        setOSDMaterial={setOSDMaterial}
                        osdCompany={osdCompany}
                        setOSDCompany={setOSDCompany}
                        storageType={storageType}
                    />
                </>
            )}
            {/* Calculate OSD Button */}
            <br />
            <CalculateOsdResultButton
                TC={{ pre: '10', post: '10', uncontrolled: '' }}
                tankHeight={tankHeight}
                storageType={storageType}
                osdCompany={osdCompany}
                computeOSDResults={computeOSDResults}
                osdResultLoading={osdResultLoading}
                userInfo={userInfo}
            />
            {/* Result Table */}
            {osdResultData && (
                <>
                    <br />
                    <OsdResultsTable
                        tableData={{
                            aboveGroundStorage: {
                                permissibleDischarge:
                                    osdResultData.tankCalcResult.permissionDischarge,
                                onSiteDetentionVolume:
                                    osdResultData.tankCalcResult.onSiteDetentionVolume.toFixed(2),
                                orificeDiameter:
                                    !tankHeight || tankHeight === 0
                                        ? 'N/A'
                                        : parseFloat(
                                              osdResultData.tankCalcResult.orificeDiameter,
                                          ).toFixed(2),
                            },
                        }}
                    />
                </>
            )}
            <br />
        </>
    );
}

const IdmDevelopmentTypeTable = ({
    selectedDevelopment,
    setSelectedDevelopment,
    setAllowableDischargeRate,
    setStorageRequired,
}) => {
    return (
        <div>
            <div className="grid-container">
                {/* Header Row */}
                <div className="radial-cell-1 w-100 h-100" />
                <div className="header-1 w-100 h-100 d-flex justify-content-center align-items-center">
                    TYPE OF DEVELOPMENT
                </div>
                <div className="header-2 w-100 h-100 d-flex justify-content-center align-items-center">
                    ORIGINAL AND REQUIRED DESIGN PARAMETERS
                </div>
                <div className="header-3 w-100 h-100 d-flex justify-content-center align-items-center">
                    ALLOWABLE DISCHARGE RATE (litres/sec/ha)
                </div>
                <div className="header-4 w-100 h-100 d-flex justify-content-center align-items-center">
                    Storage Required (litres/m²)
                </div>
                <div className="header-5 w-100 h-100 d-flex justify-content-center align-items-center">
                    VERTICAL (Tank/Basin)
                </div>
                <div className="header-6 w-100 h-100 d-flex justify-content-center align-items-center">
                    HORIZONTAL (Pipe)
                </div>
                {/* ORIGINAL AND REQUIRED DESIGN PARAMETERS */}
                <div className="radial-cell-2 w-100 h-100 d-flex justify-content-start align-items-center">
                    <input
                        type="radio"
                        className="form-check-input"
                        value={selectedDevelopment}
                        checked={
                            selectedDevelopment ===
                            'Multi-unit development (newer residential areas)'
                        }
                        onChange={() => {
                            setSelectedDevelopment(
                                'Multi-unit development (newer residential areas)',
                            );
                            setAllowableDischargeRate(65);
                            setStorageRequired({
                                vertical: 8,
                                horizontal: 10,
                            });
                        }}
                    />
                </div>
                <div className="cell-2-1 w-100 h-100 d-flex justify-content-start align-items-center">
                    Multi-unit development (newer residential areas)
                </div>
                <div className="cell-2-2 w-100 h-100">
                    C=0.5 for a 20% <b>AEP</b>
                    <br />
                    to C=0.8 for a 20% <b>AEP</b>
                </div>
                <div className="cell-2-3 w-100 h-100 d-flex justify-content-center align-items-center">
                    65
                </div>
                <div className="cell-2-4 w-100 h-100 d-flex justify-content-center align-items-center">
                    8
                </div>
                <div className="cell-2-5 w-100 h-100 d-flex justify-content-center align-items-center">
                    10
                </div>
                {/* Multi-unit development (older residential areas) */}
                <div className="radial-cell-3 w-100 h-100 d-flex justify-content-start align-items-center">
                    <input
                        type="radio"
                        className="form-check-input"
                        value={selectedDevelopment}
                        checked={
                            selectedDevelopment ===
                            'Multi-unit development (older residential areas)'
                        }
                        onChange={() => {
                            setSelectedDevelopment(
                                'Multi-unit development (older residential areas)',
                            );
                            setAllowableDischargeRate(37);
                            setStorageRequired({
                                vertical: 12,
                                horizontal: 14,
                            });
                        }}
                    />
                </div>
                <div className="cell-3-1 w-100 h-100 d-flex justify-content-start align-items-center">
                    Multi-unit development (older residential areas)
                </div>
                <div className="cell-3-2 w-100 h-100">
                    C=0.5 for a 20% 1EY event
                    <br />
                    to C=0.8 for a 20% <b>AEP</b>
                </div>
                <div className="cell-3-3 w-100 h-100 d-flex justify-content-center align-items-center">
                    37
                </div>
                <div className="cell-3-4 w-100 h-100 d-flex justify-content-center align-items-center">
                    12
                </div>
                <div className="cell-3-5 w-100 h-100 d-flex justify-content-center align-items-center">
                    14
                </div>
                {/* Industrial development (older areas) */}
                <div className="radial-cell-4 w-100 h-100 d-flex justify-content-start align-items-center">
                    <input
                        type="radio"
                        className="form-check-input"
                        value={selectedDevelopment}
                        checked={selectedDevelopment === 'Industrial development (older areas)'}
                        onChange={() => {
                            setSelectedDevelopment('Industrial development (older areas)');
                            setAllowableDischargeRate(37);
                            setStorageRequired({
                                vertical: 16,
                                horizontal: 18,
                            });
                        }}
                    />
                </div>
                <div className="cell-4-1 w-100 h-100 d-flex justify-content-start align-items-center">
                    Industrial development (older areas)
                </div>
                <div className="cell-4-2 w-100 h-100">
                    C=0.5 for a 20% 1EY event
                    <br />
                    to C=0.9 for a 10% <b>AEP</b>
                </div>
                <div className="cell-4-3 w-100 h-100 d-flex justify-content-center align-items-center">
                    37
                </div>
                <div className="cell-4-4 w-100 h-100 d-flex justify-content-center align-items-center">
                    16
                </div>
                <div className="cell-4-5 w-100 h-100 d-flex justify-content-center align-items-center">
                    18
                </div>
                {/* Industrial development (newer areas) */}
                <div className="radial-cell-5 w-100 h-100 d-flex justify-content-start align-items-center">
                    <input
                        type="radio"
                        className="form-check-input"
                        value={selectedDevelopment}
                        checked={selectedDevelopment === 'Industrial development (newer areas)'}
                        onChange={() => {
                            setSelectedDevelopment('Industrial development (newer areas)');
                            setAllowableDischargeRate(65);
                            setStorageRequired({
                                vertical: 12,
                                horizontal: 14,
                            });
                        }}
                    />
                </div>
                <div className="cell-5-1 w-100 h-100 d-flex justify-content-start align-items-center">
                    Industrial development (newer areas)
                </div>
                <div className="cell-5-2 w-100 h-100">
                    C=0.9 for a 20% 1EY event
                    <br />
                    to C=0.9 for a 10% <b>AEP</b>
                </div>
                <div className="cell-5-3 w-100 h-100 d-flex justify-content-center align-items-center">
                    65
                </div>
                <div className="cell-5-4 w-100 h-100 d-flex justify-content-center align-items-center">
                    12
                </div>
                <div className="cell-5-5 w-100 h-100 d-flex justify-content-center align-items-center">
                    14
                </div>
                <div className="radial-cell-6 w-100 h-100" />
                {/* Commercial development */}
                <div className="radial-cell-6 w-100 h-100 d-flex justify-content-start align-items-center">
                    <input
                        type="radio"
                        className="form-check-input"
                        value={selectedDevelopment}
                        checked={selectedDevelopment === 'Commercial development'}
                        onChange={() => {
                            setSelectedDevelopment('Commercial development');
                            setAllowableDischargeRate(65);
                            setStorageRequired({
                                vertical: 15,
                                horizontal: 17,
                            });
                        }}
                    />
                </div>
                <div className="cell-6-1 w-100 h-100 d-flex justify-content-start align-items-center">
                    Commercial development
                </div>
                <div className="cell-6-2 w-100 h-100">
                    C=0.9 for a 1EY event
                    <br />
                    to C=0.9 for a 5% <b>AEP</b>
                </div>
                <div className="cell-6-3 w-100 h-100 d-flex justify-content-center align-items-center">
                    65
                </div>
                <div className="cell-6-4 w-100 h-100 d-flex justify-content-center align-items-center">
                    15
                </div>
                <div className="cell-6-5 w-100 h-100 d-flex justify-content-center align-items-center">
                    17
                </div>
            </div>
        </div>
    );
};
