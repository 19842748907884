// modules
import React, { useState } from 'react';

// css
import MsfUploader from '../assets/js/MsfUploader';
import styles from './Auditor.module.css';

// utils
import { recordUserLogs } from '../../utils/s3Utils';
import { getTreatmentTrainsImage } from '../../utils/downloadMethods';
import { saveAs } from 'file-saver';
import { generateAuditorReport } from '../../utils/react-pdf/auditor-report/auditorReportGenerator';

// component
import NonCompliantCheck from './NonCompliantCheck';
import { getMelodyResult, auditMSF } from './auditorApi';
import AuditorMelodyResults from './AuditorMelodyResults';
function MusicAuditor({ userInfo, mapInfo, projectInfo, wqMethod, targetReductions }) {
    const [msfData, setMsfData] = useState();
    const [msfFilePath, setMsfFilePath] = useState();
    const [fileName, setFileName] = useState();
    const [norBeError, setNorBeError] = useState(false);
    const [otherMethodError, setOtherMethodError] = useState(false);
    const [isAuditing, setIsAuditing] = useState(false);
    const [isAuditingWithMelody, setIsAuditingWithMelody] = useState(false);
    const [madeCompliantNodes, setMadeCompliantNodes] = useState([]);
    const [showDownloadButton, setShowDownloadButton] = useState(false);
    const [reportBlob, setReportBlob] = useState(null);
    const [audited, setAudited] = useState(false);
    const [melodyRes, setMelodyRes] = useState();

    const [nonCompliantNodes, setNonCompliantNodes] = useState([]);
    const [compliantNodes, setCompliantNodes] = useState([]);
    const [nodesNotExistingInDB, setnodesNotExistingInDB] = useState([]);

    const handleRecordUserLogs = (event, file_path = msfFilePath) => {
        recordUserLogs(mapInfo.projectID, 'Auditor', event, file_path).then((res) => res.json());
        //.then((data) => console.log(data));
    };

    // TODO: extract direct access to state and make it a pure function for consistency
    const generateReport = async (melodyRes) => {
        const { msf } = msfData;
        const treatmentTrainsImageUrl = await getTreatmentTrainsImage(msf);

        if (
            compliantNodes.length > 0 ||
            nonCompliantNodes.length > 0 ||
            madeCompliantNodes.length > 0
        ) {
            let updatedCompliantNodes = [...compliantNodes];
            if (madeCompliantNodes.length > 0) {
                updatedCompliantNodes = [...updatedCompliantNodes, ...madeCompliantNodes];
                setCompliantNodes(updatedCompliantNodes);
            }

            const reportBlob = await generateAuditorReport(
                userInfo,
                projectInfo,
                updatedCompliantNodes,
                mapInfo,
                nonCompliantNodes,
                treatmentTrainsImageUrl,
                msf,
                nodesNotExistingInDB,
                melodyRes,
                targetReductions,
            );

            if (reportBlob) {
                setReportBlob(reportBlob);
                return reportBlob;
            } else {
                console.error('Failed to generate report.');
            }
        } else {
            console.log(
                'Compliant nodes and non-compliant nodes are empty. Cannot generate report.',
            );
        }
    };

    const handleAuditMSF = async (runMelody = false) => {
        setMelodyRes(undefined);
        setAudited(false);
        setMadeCompliantNodes([]); // reset the made compliant nodes for a new execution
        const msf = msfData?.msf;
        if (!msf) {
            alert(
                'The MSF could not be read. Please try again, or contact Mircea (0433030044) or Greg (0452518727) if the error persists.',
            );
            return;
        }

        if (!verifyNorbeReceivingNodes(msf)) {
            alert(
                'The MSF file does not contain the necessary receiving nodes. Please check the MSF file and try again.',
            );
            return;
        }
        if (runMelody) {
            setIsAuditingWithMelody(true);
        } else {
            setIsAuditing(true);
        }

        try {
            // Run the audit first
            const auditorRes = await auditMSF(msfData);
            handleRecordUserLogs('Auditing MSF');

            const { compliantNodes, nonCompliantNodes, nodesNotExistingInDB } = auditorRes;
            setCompliantNodes(compliantNodes);
            setNonCompliantNodes(nonCompliantNodes);
            setnodesNotExistingInDB(nodesNotExistingInDB);

            let melodyRes = null;
            // TODO: run a pre-run check here to ensure MSF is legit (check for treatment train network and broken lines)
            // Run getMelodyResult only if runMelody is true
            if (runMelody) {
                melodyRes = await getMelodyResult(msf, mapInfo.rainfallStation);
            }

            // Set the melody result if it exists
            if (melodyRes !== null) {
                setMelodyRes(melodyRes);
                console.log(melodyRes);
                console.log(melodyRes.TteResults);
                console.log(msfData);
                console.log(msfData['receivingNodeID']);
                console.log(melodyRes.TteResults[msfData['receivingNodeID']]);
                console.log(melodyRes.TteResults[0]);
            } else if (runMelody) {
                alert(
                    'Sorry, something went wrong while running the model. You may still audit the msf and generate the report without the results. Please inspect your MSF before retrying. If the problem persists, please contact Mircea (0433030044) or Greg (0452518727).',
                );
            }

            setAudited(true);
        } catch (error) {
            console.error('Error auditing MSF:', error);
            alert('An error occurred while auditing the MSF. Please try again later.');
        } finally {
            setIsAuditing(false);
            setIsAuditingWithMelody(false);
        }
    };

    // Pre & Post nodes shall only be there in NORBE nodes
    const verifyNorbeReceivingNodes = (msf, wqMethod) => {
        const NORBE_RECEIVING_NODES = [
            'Node Type,PreDevelopmentNode',
            'Node Type,PostDevelopmentNode',
        ];
        if (wqMethod === 'Norbe') {
            return NORBE_RECEIVING_NODES.every((node) => msf.includes(node));
        } else {
            return NORBE_RECEIVING_NODES.every((node) => !msf.includes(node));
        }
    };

    const handleDownloadReport = async (runMelody) => {
        let melodyResult = melodyRes;
        let report = reportBlob;

        // check if runMelody is ticked
        if (runMelody && !melodyResult) {
            melodyResult = await getMelodyResult(msfData.msf, mapInfo.rainfallStation);
            setMelodyRes(melodyResult);
            report = null;
        }
        // TEMP: pass melodyRes as arg now because the setState is asynchronous
        report = report || (await generateReport(melodyResult));
        if (report) {
            saveAs(report, `${fileName.split('.')[0]}.pdf`);
        }
    };

    const AuditorHeader = () => {
        return (
            <>
                <h3>☠️ Audit yer MUSIC Model 🏴‍☠️</h3>
                <div className="my-3">
                    Built a model but not sure if it's compliant with the specific guidelines for{' '}
                    {mapInfo.council}?
                </div>
                <div>
                    <i>Auditor of Music: The Legend of Ming</i> ⚔️ is here!
                </div>
                <div>
                    <a href="https://youtu.be/ozXJrmICwt4">
                        HELP - Teach me what all of this means
                    </a>
                </div>
            </>
        );
    };

    return (
        <>
            <AuditorHeader />
            {norBeError ? (
                <span className={styles['error-message']}>
                    The receiving nodes in the uploaded file, <b>{fileName}</b>, is
                    <b> not valid</b>. Please upload another model, using pre-development and
                    post-development end nodes to represent existing and proposed site conditions.
                </span>
            ) : otherMethodError ? (
                <span className={styles['error-message']}>
                    The end node in the uploaded file, <b>{fileName}</b>, is
                    <b> not valid.</b> Please upload another model, using receiving end node to
                    represent proposed site conditions.
                </span>
            ) : null}

            <table className={styles['auditor-table']}>
                {msfData && (
                    <div className={styles['file-details']}>
                        {showDownloadButton && (
                            <button
                                className={styles['download-button']}
                                onClick={() => {
                                    const blob = new Blob([msfData.msf], {
                                        type: 'application/octet-stream',
                                    });
                                    saveAs(blob, fileName);
                                }}
                            >
                                ⇩ Download MSF ⇩
                            </button>
                        )}
                    </div>
                )}
                <div className={styles['auditor-form-section']} style={{ border: 0 }}>
                    <MsfUploader
                        stateName={mapInfo.state}
                        authority={mapInfo.rainfallStation}
                        setMsfData={setMsfData}
                        setMsfFilePath={setMsfFilePath}
                        setFileName={setFileName}
                        setShowDownloadButton={setShowDownloadButton}
                        handleRecordUserLogs={handleRecordUserLogs}
                        msfData={msfData}
                        fileName={fileName}
                        handleAuditMSF={handleAuditMSF}
                        reportBlob={reportBlob}
                        handleDownloadReport={handleDownloadReport}
                        isAuditing={isAuditing}
                        isAuditingWithMelody={isAuditingWithMelody}
                        setReportBlob={setReportBlob}
                        audited={audited}
                        setAudited={setAudited}
                        setMadeCompliantNodes={setMadeCompliantNodes}
                    />
                </div>

                {melodyRes && (
                    <AuditorMelodyResults
                        state={mapInfo.state}
                        cityCouncil={mapInfo.council}
                        rainfallStation={mapInfo.rainfallStation}
                        overallReductions={
                            melodyRes.TteResults[msfData['receivingNodeID']]['results']
                        }
                    />
                )}

                <div style={{ textAlign: 'center' }}>
                    {audited && (
                        <div className={styles['auditor-form-section']}>
                            <NonCompliantCheck
                                nonCompliantNodes={nonCompliantNodes}
                                compliantNodes={compliantNodes}
                                msfData={msfData}
                                setMsfData={setMsfData}
                                setNonCompliantNodes={setNonCompliantNodes}
                                setCompliantNodes={setCompliantNodes}
                                fileName={fileName}
                                setReportBlob={setReportBlob}
                                setMadeCompliantNodes={setMadeCompliantNodes}
                                madeCompliantNodes={madeCompliantNodes}
                            />
                        </div>
                    )}
                </div>
            </table>
        </>
    );
}

export default MusicAuditor;
