import React, { useState, useEffect } from 'react';
// util
import { sails_api } from '../../../utils/globalConstant';
// css
import '../../../styles/modified-rational-template.css';
// component
import {
    TooltipButton,
    TooltipContainer,
    TooltipContent,
} from '../../styled-components/TooltipComponents';
import PreDevAreas from './PreDevAreas';
import PostDevAreas from './PostDevAreas';
import CalculateOsdResultButton from './CalculateOsdResultButton';
import { StorageTypeSelection } from '../StorageTypeSelection';
import OsdsAds from '../osds-ads/OsdsAds';
import { OsdResultsTable } from './OsdResultTable';

export default function RationalAuNzTemplate({
    AEPS,
    useOSDCompany,
    useStorageType,
    useOSDMaterial,
    mapInfo,
    userInfo,
    useOSDResultsData,
    setTemplateData,
    coeffs,
}) {
    const [osdCompany, setOSDCompany] = useOSDCompany;
    const [osdMaterial, setOSDMaterial] = useOSDMaterial;
    const [storageType, setStorageType] = useStorageType;
    const [osdResultData, setOsdResultData] = useOSDResultsData;

    const [companyDetails, setCompanyDetails] = useState([]);
    /* Node Data */
    // pre-dev
    const [designStorm, setDesignStorm] = useState(1);
    const [hasPsd, setHasPsd] = useState(false);
    const [councilPSD, setCouncilPSD] = useState('');
    const [tcPre, setTcPre] = useState('');
    const [preDevAreas, setPreDevAreas] = useState([
        {
            id: 0,
            areaName: '',
            surfaceArea: '',
            runoffCoefficient: '',
            surfaceType: 'Paved',
            impervious: true,
        },
    ]);
    // post-dev
    const [tcPost, setTcPost] = useState('');
    const [hasTcU, setHasTcU] = useState(false);
    const [tcU, setTcU] = useState('');
    const [postDevAreas, setPostDevAreas] = useState([
        {
            id: 0,
            areaName: '',
            surfaceArea: '',
            runoffCoefficient: '',
            surfaceType: 'Paved',
            uncontrolled: '0',
            impervious: true,
        },
    ]);
    /* Optional */
    const [climateChangeRate, setClimateChangeRate] = useState('');
    const [tankHeight, setTankHeight] = useState('');
    /* Manufacture Data */
    /* Calculated Data */
    const [sumPreDevAreas, setSumPreDevAreas] = useState(0);
    const [sumPostDevAreas, setSumPostDevAreas] = useState(0);
    const [sumUncontrolledAreas, setSumUncontrolledAreas] = useState(0);
    /* Validation */
    const [differenceBetweenPrePostAreaSize, setDifferenceBetweenPrePostAreaSize] = useState(0);
    const [preOrPostAreaSizeLarger, setPreOrPostAreaSizeLarger] = useState('equal');
    /* Display/Loading */
    const [osdResultLoading, setOsdResultLoading] = useState(false);
    const [showOsdTable, setShowOsdTable] = useState(false);

    const handleClimateChangeRateChange = (value) => {
        setShowOsdTable(false);
        const regex = /^\d+(?:\.\d{0,2})?$/i;
        if (regex.test(value) && value >= 0 && value <= 1000) {
            setClimateChangeRate(value.trim());
        } else if (value === '') {
            setClimateChangeRate('');
        }
    };

    const computeOSDResults = async () => {
        setShowOsdTable(false);
        setOsdResultLoading(true);
        setTemplateData(null);
        const osdRoute = '/OSD/osdResults/AUNZOSDResults';
        const osdPayload = {
            method: 'Rational Method',
            psd: hasPsd,
            councilPSD: councilPSD,
            intensity_coefficients: coeffs,
            totalWeightedRunoffCoefficients: {
                pre: preDevAreas.map((preDevArea) => ({
                    area: preDevArea.surfaceArea,
                    runoffCo: preDevArea.runoffCoefficient,
                })),
                post: postDevAreas.map((postDevArea) => ({
                    area: postDevArea.surfaceArea,
                    runoffCo: postDevArea.runoffCoefficient,
                })),
                uncontrolled: [],
            },
            preDevAreas: {
                areas: preDevAreas,
            },
            postDevAreas: {
                areas: postDevAreas,
            },
            climateAdjustment: 1 + climateChangeRate / 100,
            tankHeight: tankHeight,
            aepPSD: designStorm,
            aepOSD: designStorm,
            tcPre: tcPre,
            tcPost: tcPost,
            tcU: tcU,
            designStorm: designStorm,
            qaMultiplicationFactor: 2,
        };

        await sails_api
            .post(osdRoute, {
                latitude: mapInfo.coordinate.lat,
                longitude: mapInfo.coordinate.lng,
                osd: osdPayload,
                totalImpArea: +sumPostDevAreas,
                totalPArea: 0,
            })
            .then((res) => {
                if (res.data.message) alert(res.data.message);
                else {
                    setOsdResultData(res.data);
                    setShowOsdTable(true);
                }
            })
            .catch((err) => {
                console.log(err);
                throw Error('Computing OSD result error!');
            });

        setTemplateData({
            designStorm,
            psd: hasPsd,
            councilPSD: councilPSD,
            preDevAreas: {
                areas: preDevAreas,
            },
            postDevAreas: {
                areas: postDevAreas,
            },
            tcPre: tcPre ? tcPre : '0',
            tcPost: tcPost ? tcPost : '0',
            hasTcU,
            tcU: tcU ? tcU : '0',
            tankHeight: tankHeight,
            climateChangeRate: climateChangeRate ? climateChangeRate : 'N/A',
            sumPreDevAreas: sumPreDevAreas,
            sumPostDevAreas: sumPostDevAreas,
            sumUncontrolledAreas: sumUncontrolledAreas,
        });
        setOsdResultLoading(false);
    };

    // calculate the sum of pre-development areas
    useEffect(() => {
        setShowOsdTable(false);
        let sumOfAreas = 0;
        for (const item in preDevAreas) {
            sumOfAreas += +preDevAreas[item].surfaceArea;
        }
        setSumPreDevAreas(sumOfAreas);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preDevAreas]);
    // calculate the sum of post-development areas
    useEffect(() => {
        setShowOsdTable(false);
        let sumOfAreas = 0;
        let sumOfUncontrolledAreas = 0;

        for (const item in postDevAreas) {
            sumOfAreas += +postDevAreas[item].surfaceArea;
            sumOfUncontrolledAreas += +postDevAreas[item].uncontrolled;
        }

        setSumPostDevAreas(sumOfAreas);
        setSumUncontrolledAreas(sumOfUncontrolledAreas);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postDevAreas]);
    useEffect(() => {
        setDifferenceBetweenPrePostAreaSize(
            Math.abs(+sumPreDevAreas - +sumPostDevAreas).toFixed(2),
        );
        if (sumPreDevAreas > sumPostDevAreas) {
            setPreOrPostAreaSizeLarger('preDevelopment');
        } else if (sumPreDevAreas < sumPostDevAreas) {
            setPreOrPostAreaSizeLarger('postDevelopment');
        } else {
            setPreOrPostAreaSizeLarger('equal');
        }
    }, [sumPreDevAreas, sumPostDevAreas]);

    return (
        <>
            <br />
            {(coeffs !== null || AEPS !== null) && (
                <>
                    <div className="osd-blue-border">
                        {/* Boyd's and Rational Methods */}
                        <span className="title-3"> New Zealand Rational Method</span>
                        <div className="d-flex justify-content-center align-items-center">
                            <span className="flex-1">
                                Design Storm:
                                <TooltipContainer>
                                    <TooltipButton>❓</TooltipButton>
                                    <TooltipContent>
                                        <span>
                                            Leave at default value of 1% AEP if Design Storm is
                                            unknown.
                                            <br /> The design storm applies to both PSD and OSD.
                                        </span>
                                    </TooltipContent>
                                </TooltipContainer>
                            </span>
                            <select
                                className="flex-1 custom-select custom-select"
                                value={designStorm}
                                onChange={(e) => {
                                    setShowOsdTable(false);
                                    setDesignStorm(e.target.value);
                                }}
                            >
                                <option value={1} hidden>
                                    1
                                </option>
                                {AEPS?.map((aep) => (
                                    <option key={aep}>{aep}</option>
                                ))}
                            </select>
                            <span className="flex-3">(% AEP)</span>
                        </div>
                    </div>
                    <br />
                    {/* Pre-Development Areas */}
                    <div className="osd-method-area-section">
                        <span className="title-2">Pre-Development Areas</span>
                        {/* first row */}
                        <div className="d-flex justify-content-center align-items-center gap-2">
                            {/* Council PSD Check box */}
                            <div className="flex-3 d-flex justify-content-center align-items-center">
                                <span className="flex-3 h">
                                    <input
                                        name="hasPsd"
                                        type="checkbox"
                                        checked={hasPsd}
                                        onChange={() => {
                                            setShowOsdTable(false);
                                            setHasPsd(!hasPsd);
                                            setCouncilPSD('');
                                        }}
                                    />
                                    {'  '}I have Council-provided PSD:
                                    <TooltipContainer>
                                        <TooltipButton>❓</TooltipButton>
                                        <TooltipContent>
                                            <span>
                                                Uncontrolled areas are assumed to be accounted for
                                                in the calculation of PSD when PSD is provided by
                                                Council.
                                            </span>
                                        </TooltipContent>
                                    </TooltipContainer>
                                </span>
                                <input
                                    className="form-control flex-1"
                                    style={{ maxWidth: '100px' }}
                                    type="text"
                                    value={councilPSD}
                                    disabled={hasPsd === false}
                                    required={hasPsd}
                                    onChange={(e) => {
                                        setShowOsdTable(false);
                                        const regex = /^\d+(?:\.\d{0,2})?$/i;
                                        if (regex.test(e.target.value)) {
                                            setCouncilPSD(e.target.value);
                                        } else if (e.target.value === '') setCouncilPSD('');
                                    }}
                                />
                                <span className="flex-1">&nbsp;(L/s)</span>
                            </div>
                            <div className="flex-4" />
                        </div>
                        <br />
                        {/* second row */}
                        {/* slope and length of flowpath */}
                        {!hasPsd && (
                            <div className="d-flex">
                                <div className="d-flex justify-content-center align-items-center gap-2">
                                    <span className="flex-2 text-right" style={{ width: '10vw' }}>
                                        Times of Concentration (min):
                                    </span>
                                    <input
                                        className="flex-1 form-control"
                                        value={tcPre}
                                        onChange={(e) => {
                                            setShowOsdTable(false);
                                            const regex = /^\d+(?:\.\d{0,2})?$/i;
                                            if (regex.test(e.target.value)) {
                                                setTcPre(e.target.value);
                                            } else if (e.target.value === '') setTcPre('');
                                        }}
                                    />
                                </div>
                                <div className="flex-1" />
                            </div>
                        )}
                        <br />
                        {/* third row */}
                        {!hasPsd && (
                            <>
                                <div>
                                    <PreDevAreas
                                        preDevAreas={preDevAreas}
                                        setPreDevAreas={setPreDevAreas}
                                        options={{
                                            hideCy: true,
                                        }}
                                    />
                                </div>
                                <br />
                                {/* Add New Button & Sum of Areas */}
                                <div className="d-flex justify-content-center align-items-center">
                                    <button
                                        type="button"
                                        className="flex-1 btn btn-sm btn-primary"
                                        onClick={() => {
                                            setShowOsdTable(false);
                                            const newItem = {
                                                id: preDevAreas[preDevAreas.length - 1].id + 1,
                                                areaName: '',
                                                surfaceArea: '',
                                                runoffCoefficient: '',
                                                surfaceType: 'Paved',
                                                impervious: true,
                                            };
                                            const bufArray = [...preDevAreas, newItem];
                                            setPreDevAreas(bufArray);
                                        }}
                                    >
                                        ADD NEW AREA
                                    </button>
                                    <div className="flex-1" />
                                    <span className="flex-3">
                                        Total Pre-Development Areas:&nbsp; {sumPreDevAreas}
                                        &nbsp;{' '}
                                        {!hasPsd &&
                                            preOrPostAreaSizeLarger === 'postDevelopment' && (
                                                <span className="text-danger">
                                                    <b>
                                                        Please add{' '}
                                                        {differenceBetweenPrePostAreaSize} m
                                                        <sup>2</sup> of catchment area!
                                                    </b>
                                                </span>
                                            )}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                    <br />
                    {/* Post-Development Areas */}
                    <div className="osd-method-area-section">
                        <span className="title-2">Post-Development Areas</span>
                        {/* slope and length of flowpath */}
                        <div className="d-flex">
                            <div className="d-flex justify-content-left align-items-center gap-2">
                                <span className="flex-2 text-right" style={{ width: '10vw' }}>
                                    Times of Concentration (min):
                                </span>
                                <input
                                    className="flex-1 form-control"
                                    value={tcPost}
                                    onChange={(e) => {
                                        setShowOsdTable(false);
                                        const regex = /^\d+(?:\.\d{0,2})?$/i;
                                        if (regex.test(e.target.value)) {
                                            setTcPost(e.target.value);
                                        } else if (e.target.value === '') setTcPost('');
                                    }}
                                />
                            </div>
                            <div className="flex-1" />
                        </div>
                        {/* uncontrolled */}
                        {!hasPsd && (
                            <div className="d-flex justify-content-center align-items-center gap-1">
                                <div className="flex-2 d-flex justify-content-center align-items-center gap-2">
                                    <input
                                        type="checkbox"
                                        checked={hasTcU}
                                        onChange={() => {
                                            setShowOsdTable(false);
                                            setHasTcU(!hasTcU);
                                            setTcU('');
                                        }}
                                    />
                                    <span className="flex-2 text-right">
                                        Times of Concentration Uncontrolled (min):&nbsp;
                                    </span>
                                    <input
                                        className="flex-1 form-control"
                                        value={tcU}
                                        onChange={(e) => {
                                            setShowOsdTable(false);
                                            const regex = /^\d+(?:\.\d{0,2})?$/i;
                                            if (regex.test(e.target.value)) {
                                                setTcU(e.target.value);
                                            } else if (e.target.value === '') setTcU('');
                                        }}
                                        placeholder="(optional)"
                                    />
                                </div>
                                <div className="flex-1" />
                            </div>
                        )}
                        <br />
                        {/* second row */}
                        <div>
                            <PostDevAreas
                                postDevAreas={postDevAreas}
                                setPostDevAreas={setPostDevAreas}
                                options={{
                                    hideCy: true,
                                }}
                            />
                        </div>
                        <br />
                        {/* Add New Button & Sum of Areas */}
                        <div className="d-flex justify-content-center align-items-center">
                            <button
                                type="button"
                                className="flex-1 btn btn-sm btn-primary"
                                onClick={() => {
                                    const newItem = {
                                        id: postDevAreas[postDevAreas.length - 1].id + 1,
                                        areaName: '',
                                        surfaceArea: '',
                                        runoffCoefficient: '',
                                        surfaceType: 'Paved',
                                        uncontrolled: '0',
                                        impervious: true,
                                    };
                                    const bufArray = [...postDevAreas, newItem];
                                    setPostDevAreas(bufArray);
                                }}
                            >
                                ADD NEW AREA
                            </button>
                            <div className="flex-1" />
                            <span className="flex-3">
                                Total Post-Development Areas:&nbsp; {sumPostDevAreas}&nbsp;{' '}
                                {!hasPsd && preOrPostAreaSizeLarger === 'preDevelopment' && (
                                    <span className="text-danger">
                                        <b>
                                            Please add {differenceBetweenPrePostAreaSize} m
                                            <sup>2</sup> of catchment area!{' '}
                                        </b>
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    <br />
                    {/* optional */}
                    <div id="osd-form-optional-row" className="d-flex flex-column flex-wrap">
                        <h4 className="title-2 flex-1">Optional</h4>
                        <div
                            id="climate-change-rate"
                            className="d-flex justify-content-start align-items-center flex-wrap"
                        >
                            - Increase OSD storage volume for Climate Change variation by:{' '}
                            <input
                                type="number"
                                className="form-control"
                                style={{ maxWidth: '100px' }}
                                value={climateChangeRate}
                                onChange={(e) => handleClimateChangeRateChange(e.target.value)}
                            />{' '}
                            % from calculated results.
                            <TooltipContainer>
                                <TooltipButton>❓</TooltipButton>
                                <TooltipContent>
                                    <span>
                                        Default value as per Council rules. Please consult Council
                                        Engineer if a lower value is desired.
                                        <br /> 0% indicates no climate change adjustment.
                                    </span>
                                </TooltipContent>
                            </TooltipContainer>
                        </div>
                        <div className="flex-1 d-flex justify-content-start align-items-center flex-row">
                            <span>- Height of Storage Above Orifice:</span>
                            <input
                                type="text"
                                className="form-control"
                                style={{ maxWidth: '100px' }}
                                value={tankHeight}
                                min={0}
                                placeholder="Optional"
                                onChange={(e) => {
                                    setShowOsdTable(false);
                                    const regex = /^\d+(?:\.\d{0,2})?$/i;
                                    if (regex.test(e.target.value)) setTankHeight(e.target.value);
                                    else if (e.target.value === '') setTankHeight('');
                                }}
                            />
                            (m)
                        </div>
                    </div>
                    <br />
                    {/* OSD Material */}
                    <div>
                        <StorageTypeSelection
                            mapInfo={mapInfo}
                            setStorageType={setStorageType}
                            setOSDCompany={setOSDCompany}
                            setCompanyDetails={setCompanyDetails}
                            setShowOsdTable={setShowOsdTable}
                        />
                    </div>
                    {storageType && (
                        <OsdsAds
                            storageType={storageType}
                            companyDetails={companyDetails}
                            osdMaterial={osdMaterial}
                            setOSDMaterial={setOSDMaterial}
                            osdCompany={osdCompany}
                            setOSDCompany={setOSDCompany}
                            setShowOsdTable={setShowOsdTable}
                            setOsdResultData={setOsdResultData}
                        />
                    )}

                    {/* Calculate OSD Result Button */}
                    <div>
                        <CalculateOsdResultButton
                            AEPS={AEPS}
                            coeffs={coeffs}
                            hasPsd={hasPsd}
                            councilPSD={councilPSD}
                            sumPreDevAreas={sumPreDevAreas}
                            sumPostDevAreas={sumPostDevAreas}
                            tcPre={tcPre}
                            tcPost={tcPost}
                            hasTcU={hasTcU}
                            tcU={tcU}
                            preDevAreas={preDevAreas}
                            postDevAreas={postDevAreas}
                            tankHeight={tankHeight}
                            storageType={storageType}
                            osdCompany={osdCompany}
                            computeOSDResults={computeOSDResults}
                            osdResultLoading={osdResultLoading}
                            userInfo={userInfo}
                        />
                    </div>

                    {/* Results Table */}
                    {showOsdTable && Object.keys(osdResultData).length !== 0 && (
                        <OsdResultsTable
                            osdResultData={osdResultData}
                            hasPsd={hasPsd}
                            councilPSD={councilPSD}
                            designStorm={designStorm}
                            climateChangeRate={climateChangeRate}
                        />
                    )}
                </>
            )}
        </>
    );
}
