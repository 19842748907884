import React, { useState } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch to dispatch actions
import { handleDeltaT, handleIFD } from '../../../utils/redux/osdStateDTSlice';
import Tooltip from '../../common/Tooltip/Tooltip';

const IfdDataset = ({ setOsdResultData }) => {
    const dispatch = useDispatch(); // Initialize dispatch to interact with Redux store

    const [deltaT, setDeltaT] = useState(null); // Local state to track deltaT value

    const handleInputChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const value = selectedOption.value; // Selected option's value
        const label = selectedOption.text; // Selected option's label
        const group = selectedOption.parentNode.label;
        dispatchDeltaT(value);
        dispatch(
            handleIFD({
                scenario: group,
                term: label,
            }),
        );
    };

    const dispatchDeltaT = (selectedValue) => {
        const deltaTMap = {
            noChange: 0,
            currentSsp1: 1.2,
            mediumSsp1: 1.4,
            longSsp1: 1.5,
            currentSsp2: 1.2,
            mediumSsp2: 1.7,
            longSsp2: 2.4,
            currentSsp3: 1.2,
            mediumSsp3: 1.8,
            longSsp3: 3.3,
            currentSsp4: 1.3,
            mediumSsp4: 2.1,
            longSsp4: 4.1,
        };

        const newDeltaT = deltaTMap[selectedValue];

        if (newDeltaT !== undefined) {
            // Update the local state
            setDeltaT(newDeltaT);
            setOsdResultData(null);
            // Dispatch action to Redux store to update the deltaT value
            dispatch(handleDeltaT(newDeltaT));
        } else {
            console.warn(`No mapping found for selected value: ${selectedValue}`);
        }
    };

    const dropdownOptions = [
        {
            options: [{ label: '2016 IFD Data', value: 'noChange' }],
        },
        {
            label: 'SSP1-2.6',
            options: [
                { label: 'Current and Near-term (2021-2040)', value: 'currentSsp1' },
                { label: 'Medium-term (2041-2060)', value: 'mediumSsp1' },
                { label: 'Long-term (2060-2100)', value: 'longSsp1' },
            ],
        },
        {
            label: 'SSP2-4.5',
            options: [
                { label: 'Current and Near-term (2021-2040)', value: 'currentSsp2' },
                { label: 'Medium-term (2041-2060)', value: 'mediumSsp2' },
                { label: 'Long-term (2060-2100)', value: 'longSsp2' },
            ],
        },
        {
            label: 'SSP3-7',
            options: [
                { label: 'Current and Near-term (2021-2040)', value: 'currentSsp3' },
                { label: 'Medium-term (2041-2060)', value: 'mediumSsp3' },
                { label: 'Long-term (2060-2100)', value: 'longSsp3' },
            ],
        },
        {
            label: 'SSP4-8.5',
            options: [
                { label: 'Current and Near-term (2021-2040)', value: 'currentSsp4' },
                { label: 'Medium-term (2041-2060)', value: 'mediumSsp4' },
                { label: 'Long-term (2060-2100)', value: 'longSsp4' },
            ],
        },
    ];

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <label
                htmlFor="ifd-dataset"
                style={{
                    whiteSpace: 'nowrap',
                    alignSelf: 'center',
                    marginTop: '10px',
                    marginRight: '1px',
                }}
            >
                IFD Dataset
                <Tooltip link="https://docs.google.com/document/d/1opr9SXwyXENVKYHUpTM48_oC4zRoTy33/edit">
                    Select IFD data to be used in OSD calculations. The default is 2016 IFD from
                    BOM. Other options include climate change adjusted IFD based on climate change
                    scenarios as per procedure recommended in AR&R Book1, Chapter 6. For more info,
                    click on ?
                </Tooltip>
            </label>
            <select
                id="ifd-dataset"
                onChange={handleInputChange}
                style={{ paddingRight: '2rem', paddingLeft: '1rem' }}
            >
                {dropdownOptions.map((group) => (
                    <optgroup key={group.label} label={group.label}>
                        {group.options.map((item) => (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        ))}
                    </optgroup>
                ))}
            </select>
        </div>
    );
};

export default IfdDataset;
