import React from 'react';
import { Button } from 'react-bootstrap';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { truncateDecimal } from '../../../utils/common';

const StormDurationsChart = ({ chartRef, osdResultData, downloadChartAsImage }) => {
    const data = osdResultData?.vreq_n_data?.map((item) => {
        const keys = Object.keys(item);
        const obj = {};
        keys.forEach((key) => {
            const value = item[key];
            const isValueNumber = typeof value === 'number';
            obj[key] = isValueNumber ? truncateDecimal(value, 3) : value;
        });
        return obj;
    });

    return (
        <div className="osd-form-section-container osd-blue-border">
            <div className="osd-form-section">
                <span className="title-2 span-7">
                    Range of Durations used to Determine Max Volume Required
                </span>
            </div>
            <div ref={chartRef} className="chart-container">
                <LineChart
                    width={450}
                    height={400}
                    data={data}
                    margin={{ top: 50, left: 50, bottom: 50, right: 30 }}
                >
                    <Line type="monotone" dataKey="Vreq_n" stroke="#8884d8" />

                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                        dataKey="t_dur"
                        type="number"
                        tickCount={8}
                        label={{
                            value: 'Duration (min)',
                            position: 'insideBottom',
                            textAnchor: 'middle',
                            offset: -10,
                        }}
                    />
                    <YAxis
                        label={{
                            value: 'OSD (m³)',
                            angle: -90,
                            position: 'insideLeft',
                            textAnchor: 'middle',
                            offset: -10,
                        }}
                    />

                    <Tooltip content={<CustomTooltip />} />
                </LineChart>
            </div>
            <Button variant="info" onClick={downloadChartAsImage} className="btn btn-primary">
                Download Chart
            </Button>
        </div>
    );
};

const CustomTooltip = ({ active, payload, label }) => {
    const tooltipStyles = {
        border: '1px solid #ccc',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: '#fff',
    };

    const tooltipLabelStyles = {
        margin: 0,
        padding: 0,
    };

    if (active && payload && payload.length) {
        return (
            <div style={tooltipStyles}>
                <p
                    style={{ ...tooltipLabelStyles, color: '#8884d8' }}
                >{`Duration: ${label} min`}</p>
                <p
                    style={{ ...tooltipLabelStyles, color: '#d07a50' }}
                >{`OSD: ${payload[0].value} m³`}</p>
            </div>
        );
    }

    return null;
};

export default StormDurationsChart;
