// modules
import axios from 'axios';
// utils
import { LAMBDA_API } from './globalConstant';

export const tankCalc = async (
    imperviousArea,
    allowableDischargeRate,
    storageRequired,
    climateAdjustment,
    tankHeight,
) => {
    const payload = {
        endpoint: 'tankCalc',
        params: {
            imperviousArea: imperviousArea ? imperviousArea : 0,
            climateChangeAdjustment: climateAdjustment ? climateAdjustment : 1,
            heightOfStorageAbove: tankHeight ? tankHeight : 0,
            allowableDischargeRate: allowableDischargeRate ? allowableDischargeRate : 0,
            storagRequired: storageRequired ? storageRequired : 0,
        },
    };

    let result;
    await axios
        .post(`${LAMBDA_API}/idm-calc`, payload)
        .then((res) => {
            result = res.data.results;
        })
        .catch(() => {
            result = 'Oppps! Something wrong, please try again!';
        });

    return result;
};

export const pipeCalc = async (
    imperviousArea,
    allowableDischargeRate,
    storageRequired,
    climateAdjustment,
    tankHeight,
    deltaT,
) => {
    const payload = {
        endpoint: 'pipeCalc',
        params: {
            imperviousArea: imperviousArea ? imperviousArea : 0,
            climateChangeAdjustment: climateAdjustment ? climateAdjustment : 1,
            heightOfStorageAbove: tankHeight ? tankHeight : 0,
            allowableDischargeRate: allowableDischargeRate ? allowableDischargeRate : 0,
            storagRequired: storageRequired ? storageRequired : 0,
            deltaT: deltaT,
        },
    };

    let result;
    await axios
        .post(`${LAMBDA_API}/idm-calc`, payload)
        .then((res) => {
            result = res.data.results;
        })
        .catch(() => {
            result = 'Oppps! Something wrong, please try again!';
        });

    return result;
};
